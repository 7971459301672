import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SvgIconComponent } from '@shared/components/svg-icon/svg-icon.component';
import { CommonModule } from '@angular/common';

export enum MapNotificationModalEnum {
  RouteCreated,
  GastStationAdded,
  GasStationRemoved,
  RouteError,
}

@Component({
  selector: 'qsc-map-notification-modal',
  templateUrl: './map-notification-modal.component.html',
  styleUrls: ['./map-notification-modal.component.scss'],
  standalone: true,
  imports: [SvgIconComponent, TranslateModule, CommonModule],
})
export class MapNotificationModalComponent implements OnInit {
  notificationModalType?: MapNotificationModalEnum;

  constructor(protected readonly bsModalRef: BsModalRef) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.bsModalRef.hide();
    }, 3000);
  }

  protected getTitle(): string {
    switch (this.notificationModalType) {
      case MapNotificationModalEnum.RouteCreated:
        return '@VEHICLES-CONTENT-MAPS-MODAL-TITLE-1';
      case MapNotificationModalEnum.GastStationAdded:
        return '@VEHICLES-CONTENT-MAPS-MODAL-TITLE-2';
      case MapNotificationModalEnum.GasStationRemoved:
        return '@VEHICLES-CONTENT-MAPS-MODAL-TITLE-3';
      case MapNotificationModalEnum.RouteError:
        return '@VEHICLES-CONTENT-MAPS-MODAL-TITLE-6';
      default:
        return '@VEHICLES-CONTENT-MAPS-MODAL-TITLE-1';
    }
  }

  protected isRouteError(): boolean {
    return this.notificationModalType === MapNotificationModalEnum.RouteError;
  }
}
