[
    {
        "installation": 588740,
        "name": "Paulista De Americana",
        "city": "Americana",
        "postalCode": "13478-580",
        "address": "Av. Paulista, 499",
        "area": "Vila Nossa Sra. de Fatima",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7374227185,
        "longitude": -47.3203888893,
        "localName": "Ale"
    },
    {
        "installation": 4002216682,
        "name": "Auto Posto E Restaurante Aparecida Ltda",
        "city": "Aparecida",
        "postalCode": "12573-240",
        "address": "Rod Pres Dutra, Km 71",
        "area": "Sta Luzia",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.84846294971051,
        "longitude": -45.22667534312743,
        "localName": "Posto Aparecida"
    },
    {
        "installation": 728167,
        "name": "Lavajato Aquarius Ltda",
        "city": "Araras",
        "postalCode": "13601-200",
        "address": "Av. Pe Alarico Zacharias, 70",
        "area": "Jardim Belvedere",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.3608686016,
        "longitude": -47.3878401318,
        "localName": "Lavajato Aquarius Ltda"
    },
    {
        "installation": 982813,
        "name": "Posto De Serviços Duque Alphaville Ltda.",
        "city": "Barueri",
        "postalCode": "06454-000",
        "address": "Alameda Rio Negro Nº 600",
        "area": "Alphaville Industrial",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4974112863,
        "longitude": -46.8482107608,
        "localName": "Posto Shell - Posto Servicos Duque Alphaville Ltd"
    },
    {
        "installation": 4000502306,
        "name": "Auto Posto Duque Barueri Ltda.",
        "city": "Barueri",
        "postalCode": "06411-250",
        "address": "Rua Campos Sales, 934",
        "area": "Barueri",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5039009999,
        "longitude": -46.8771600869,
        "localName": "Posto BR + GNV | Rede Duque - Barueri"
    },
    {
        "installation": 911182,
        "name": "Rede De Postos Sete Estrelas Ltda Tigrão - Posto 52",
        "city": "Cacapava",
        "postalCode": "12287-260",
        "address": "Rodovia Presidente Dutra , Km 133",
        "area": "Vila Antonio Augusto Luiz",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1233658526,
        "longitude": -45.7297146686,
        "localName": "Rede de Postos Sete Estrelas - Posto 52"
    },
    {
        "installation": 620938,
        "name": "Sete Estrelas Caçapava - Filial 19",
        "city": "Cacapava",
        "postalCode": "12287-310",
        "address": "Rua Professor João Batista Ortiz Monteiro, 613",
        "area": "Vila Antonio Augusto Luiz",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1158360065,
        "longitude": -45.7151790706,
        "localName": "Sete Estrelas - Filial 19"
    },
    {
        "installation": 925589,
        "name": "Flor Do Vera",
        "city": "Caieiras",
        "postalCode": "07700-000",
        "address": "Rodovia Presidente Tancredo De A. Neves   Km 39",
        "area": "Caieiras",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.3320109208,
        "longitude": -46.7478487591,
        "localName": "Auto Posto Flor do Vera"
    },
    {
        "installation": 737948,
        "name": "Auto Posto Luma De Cajamar Ltda.",
        "city": "Cajamar",
        "postalCode": "07750-000",
        "address": "Av. Arnaldo Rojek, 35",
        "area": "Cajamar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.3344628416,
        "longitude": -46.8360293399,
        "localName": "Auto Posto Luma de Cajamar Ltda."
    },
    {
        "installation": 4002222425,
        "name": "Auto Posto Colinas Verde Ltda",
        "city": "Cajamar",
        "postalCode": "07760-000",
        "address": "Avenida Jordano Mendes 246",
        "area": "Jordanésia",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.33367450309325,
        "longitude": -46.83797860025764,
        "localName": "Auto Posto Colinas Verde Ltda"
    },
    {
        "installation": 816527,
        "name": "Bg Gnv Do Brasil Ltda -Posto Futuro",
        "city": "Campinas",
        "postalCode": "13084-008",
        "address": "Rua: Albino Jose Barbosa De Oliveira, 360",
        "area": "Barao De Geraldo",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.8355784929,
        "longitude": -47.0788872079,
        "localName": "BG GNV do Brasil Ltda - Posto Futuro"
    },
    {
        "installation": 855045,
        "name": "Auto Posto Barao De Itapura Campinas Ltda",
        "city": "Campinas",
        "postalCode": "13020-431",
        "address": "Av. Barao De Itapura 641",
        "area": "Botafogo",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.8976419413,
        "longitude": -47.0689605029,
        "localName": "Auto Posto Barão de Itapura Campinas Ltda"
    },
    {
        "installation": 855047,
        "name": "Posto Andorinhas Ltda",
        "city": "Campinas",
        "postalCode": "13025-320",
        "address": "Av. José De Souza Campos, 2101",
        "area": "Cambui",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.8878808238,
        "longitude": -47.044949162,
        "localName": "Posto Andorinhas Ltda"
    },
    {
        "installation": 562851,
        "name": "Flamingo",
        "city": "Campinas",
        "postalCode": "13033-000",
        "address": "Av. John B. Dunlop, 460",
        "area": "Cidade Satelite Iris",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9104555306,
        "longitude": -47.0968945898,
        "localName": "Posto Shell - Flamingo"
    },
    {
        "installation": 841184,
        "name": "Rodocamp Auto Service",
        "city": "Campinas",
        "postalCode": "13054-750",
        "address": "Av. Mercedez Benz 01 Q1 Qt 8907",
        "area": "Dist Indus De Campinas",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0022929528,
        "longitude": -47.1134065067,
        "localName": "Posto Ipiranga GNV - Rodocamp Auto Service"
    },
    {
        "installation": 860897,
        "name": "Nn Amoreiras",
        "city": "Campinas",
        "postalCode": "13050-035",
        "address": "Av. Amoreiras, 3.635",
        "area": "Jardim Do Lago",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9336651639,
        "longitude": -47.0912552179,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 737563,
        "name": "Auto Posto Jardim Do Trevo Ltda.",
        "city": "Campinas",
        "postalCode": "13030-100",
        "address": "Av. Benedito De Campos, 193",
        "area": "Jardim Do Trevo",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9303598514,
        "longitude": -47.0708422911,
        "localName": "Auto Posto Jardim do Trevo Ltda."
    },
    {
        "installation": 911183,
        "name": "Posto Garcia De Campinas Ltda",
        "city": "Campinas",
        "postalCode": "13063-370",
        "address": "Rod. Anhanguera, Km 99 S / N",
        "area": "Jardim Eulina",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.8885936158,
        "longitude": -47.1117368569,
        "localName": "Posto Garcia de Campinas Ltda"
    },
    {
        "installation": 649162,
        "name": "Auto Posto Global",
        "city": "Campinas",
        "postalCode": "13080-230",
        "address": "Av. Estácio De Sá X Av. Marquês De Valença",
        "area": "Jardim Santa Genebra",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.8448924755,
        "longitude": -47.069413967,
        "localName": "Auto Posto Global"
    },
    {
        "installation": 884496,
        "name": "Auto Posto 2002",
        "city": "Campinas",
        "postalCode": "13100-040",
        "address": "Av. Princesa D´Oeste, 2002",
        "area": "Mansoes Santo Antonio",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9061695939,
        "longitude": -47.0458458448,
        "localName": "Auto Posto 2002"
    },
    {
        "installation": 4002109503,
        "name": "Rodoposto Turmalina Ltda",
        "city": "Cordeiropolis",
        "postalCode": "13492-442",
        "address": "Rodovia Anhanguera, Km 158",
        "area": "Cordeirópolis",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.4533580827,
        "longitude": -47.3978672011,
        "localName": "Posto Petrobras"
    },
    {
        "installation": 688301,
        "name": "Sete Estrelas Cruzeiro - Posto 39",
        "city": "Cruzeiro",
        "postalCode": "12702-010",
        "address": "Rua Rotary Clube, 768",
        "area": "Jardim America",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.5890213807,
        "longitude": -44.9626428811,
        "localName": "Sete Estrelas - Posto 39"
    },
    {
        "installation": 546484,
        "name": "Posto Vila Rica São Cristovão",
        "city": "Cruzeiro",
        "postalCode": "12712-010",
        "address": "Av. Minas Gerais, 300 ",
        "area": "Retiro Da Mantiqueira",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.5651402098,
        "longitude": -44.9514994821,
        "localName": "Posto Vila Rica"
    },
    {
        "installation": 597183,
        "name": "Ecoposto  Via Brasil Ecovias",
        "city": "Diadema",
        "postalCode": "09941-201",
        "address": "Av Fabio Eduardo Ramos Esquivel, 2015",
        "area": "Diadema",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.683121288,
        "longitude": -46.605377818,
        "localName": "Posto Setee - Ecoposto"
    },
    {
        "installation": 507249,
        "name": "Clube Dos 500  (Antigo Posto E Restaurante Três Garças",
        "city": "Guaratingueta",
        "postalCode": "12522-640",
        "address": "Rod. Pres. Dutra Km 60",
        "area": "Vila Sao Jose",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7883562829,
        "longitude": -45.1563779412,
        "localName": "Posto Rodogás / GNV"
    },
    {
        "installation": 475019,
        "name": "Posto E Restaurante Três Garças",
        "city": "Guaratingueta",
        "postalCode": "12522-640",
        "address": "Rod. Pres. Dutra Km 59",
        "area": "Vila Sao Jose",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.7823045227,
        "longitude": -45.1499967682,
        "localName": "Posto Petrobras GNV"
    },
    {
        "installation": 4001367478,
        "name": "Rede Duque - Aeroporto De Gru",
        "city": "Guarulhos",
        "postalCode": "07190-100",
        "address": "Rod Hélio Smidt, S/N",
        "area": "Aeroporto",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4328711315,
        "longitude": -46.4929186761,
        "localName": "Rede Duque"
    },
    {
        "installation": 520239,
        "name": "Nikigas Comercial  Padre Celestino",
        "city": "Guarulhos",
        "postalCode": "07013-100",
        "address": "R. Padre. Celestino, 426",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4731899677,
        "longitude": -46.5258510959,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 4002087266,
        "name": "Autoposto Lisboa",
        "city": "Guarulhos",
        "postalCode": "07151-440",
        "address": "Av Osiris, 21",
        "area": "Cidade Seródio",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4142630157,
        "longitude": -46.4616899701,
        "localName": "Auto Posto Lisboa"
    },
    {
        "installation": 487834,
        "name": "Posto De Serviços Gas Dutra  - (Antigo Platanos) Atual Posto Puglia",
        "city": "Guarulhos",
        "postalCode": "07210-000",
        "address": "Rod. Presidente Dutra, Km 225",
        "area": "Itapegica",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4869575145,
        "longitude": -46.5418532839,
        "localName": "Posto Serviços Gás Dutra"
    },
    {
        "installation": 665825,
        "name": "Auto Posto J.K.",
        "city": "Guarulhos",
        "postalCode": "07252-000",
        "address": "Av. Presidente Juscelino K. De Oliveira, 09",
        "area": "Jardim Albertina",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4689715963,
        "longitude": -46.4228184154,
        "localName": "Auto Posto J.K."
    },
    {
        "installation": 763595,
        "name": "Centro Automotivo Rsb Ltda",
        "city": "Guarulhos",
        "postalCode": "07252-000",
        "address": "Est Pres Juscelino Kubitschek De Oliveira, 390",
        "area": "Jardim Albertina",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4324314356,
        "longitude": -46.406229371,
        "localName": "Posto Ipiranga - Rsb"
    },
    {
        "installation": 636460,
        "name": "Novo Anel-Centro Automotivo Canaiolo Ltda",
        "city": "Guarulhos",
        "postalCode": "07033-010",
        "address": "Av. Aricanduva, S / N(789 Pista Norte) Ou Educador Paulo Freire 822",
        "area": "Jardim Munhoz",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5051617295,
        "longitude": -46.558447105,
        "localName": "Novo Anel-Centro Automotivo Canaiolo Ltda"
    },
    {
        "installation": 500795,
        "name": "Carreteiro Revendedor De Petr. E Der.",
        "city": "Guarulhos",
        "postalCode": "07034-000",
        "address": "Rod. Pres. Dutra, Km 221,1",
        "area": "Parque Cecap",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4682032244,
        "longitude": -46.5117202362,
        "localName": "Posto Carreteiro Norte"
    },
    {
        "installation": 677579,
        "name": "Nikigas Comercial  Guarulhos- Matriz",
        "city": "Guarulhos",
        "postalCode": "07031-001",
        "address": "Av. Guarulhos, 4331",
        "area": "Ponte Grande",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5039841472,
        "longitude": -46.5517358336,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 4000203757,
        "name": "Centro Automotivo Asas De Cumbica Ltda.",
        "city": "Guarulhos",
        "postalCode": "07140-040",
        "address": "Rua Santo Expedito, 450",
        "area": "Vila Barros",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.447476184,
        "longitude": -46.4951315404,
        "localName": "Centro Automotivo Asas de Cumbica Ltda."
    },
    {
        "installation": 499926,
        "name": "Argetax Guarulhos",
        "city": "Guarulhos",
        "postalCode": "07042-000",
        "address": "Av.Rotary, 768",
        "area": "Vila Das Bandeiras",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4851389177,
        "longitude": -46.5513448271,
        "localName": "Posto Shell"
    },
    {
        "installation": 647019,
        "name": "Centro Automotivo Guarulhos (Auto Posto Mfp Polli)",
        "city": "Guarulhos",
        "postalCode": "07042-000",
        "address": "Av. Rotary, 710",
        "area": "Vila Das Bandeiras",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4858623466,
        "longitude": -46.5509469744,
        "localName": "Rede Duque"
    },
    {
        "installation": 4002057002,
        "name": "Posto De Serviços Automotivos E Comercio De Combustivel Madri Ltda",
        "city": "Guarulhos",
        "postalCode": "07030-010",
        "address": "Rua Anton Philips, 119",
        "area": "Vila Herminia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4902799293566,
        "longitude": -46.5465796801494,
        "localName": "Posto de Serviços Automotivos e Comercio de Combustivel Madri Ltda"
    },
    {
        "installation": 869959,
        "name": "Cecap Guarulhos Auto Posto Ltda",
        "city": "Guarulhos",
        "postalCode": "07190-020",
        "address": "Rua Asea, 219",
        "area": "Vila Monteiro Lobato",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4633226304,
        "longitude": -46.499223558,
        "localName": "Cecap Guarulhos Auto Posto Ltda"
    },
    {
        "installation": 953138,
        "name": "Centro Automotivo Trevo De Bonsucesso Ltda",
        "city": "Guarulhos",
        "postalCode": "07175-090",
        "address": "Estrada De Capuava,10",
        "area": "Vila Nova Bonsucesso",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4249544336,
        "longitude": -46.4078520031,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 637982,
        "name": "Nova Trabalhadores",
        "city": "Guarulhos",
        "postalCode": "07220-020",
        "address": "Av. Antonio Bardela, 71",
        "area": "Zona Industrial Cumbica",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4785213152,
        "longitude": -46.4676259649,
        "localName": "Posto Nova Trabalhadores"
    },
    {
        "installation": 589409,
        "name": "Remanso",
        "city": "Hortolandia",
        "postalCode": "13184-420",
        "address": "Rua Luis Camilo De Camargo, 840",
        "area": "Loteam Remanso Campineiro",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.865049654,
        "longitude": -47.212892142,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 967216,
        "name": "Centro Comercial De Comb.Hortolândia  Posto Madri",
        "city": "Hortolandia",
        "postalCode": "13183-000",
        "address": "Av. Anhanguera 150",
        "area": "Vila Real",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.8586089768,
        "longitude": -47.2159294118,
        "localName": "Auto Posto Madri"
    },
    {
        "installation": 598173,
        "name": "Cidade Do Sol",
        "city": "Indaiatuba",
        "postalCode": "13344-610",
        "address": "Av. Francisco De Paula Leite N° 2293 - Escritorio Rua S. Sebastião, 1823 -Sto Amaro",
        "area": "Jardim Nely",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1116042586,
        "longitude": -47.2196021196,
        "localName": "Auto Posto Cidade do Sol"
    },
    {
        "installation": 4000207199,
        "name": "Posto Renascença",
        "city": "Itapevi",
        "postalCode": "06694-040",
        "address": "Av. Presidente Vargas, 833",
        "area": "Itapevi",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5381721872,
        "longitude": -46.9391301958,
        "localName": "Renascença Serviços Automotivo"
    },
    {
        "installation": 687538,
        "name": "Itaquagas",
        "city": "Itaquaquecetuba",
        "postalCode": "08582-545",
        "address": "Av. Henrique Eroles 455 X R. Itaquaquecetuba",
        "area": "Jardim Luciana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4851047998,
        "longitude": -46.3731964026,
        "localName": "Itaquagas Auto posto ltda. Ceprano GNV"
    },
    {
        "installation": 500377,
        "name": "J. Gava Auto Posto",
        "city": "Itatiba",
        "postalCode": "13253-200",
        "address": "Rua Santo Antônio,717",
        "area": "Jardim Carlos Borella",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0020202097,
        "longitude": -46.8268361253,
        "localName": "Posto Platina"
    },
    {
        "installation": 656604,
        "name": "Posto Trevo De Itatiba",
        "city": "Itatiba",
        "postalCode": "13255-350",
        "address": "Av. Mal Castelo Branco, 845",
        "area": "Jardim Da Luz",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0226726788,
        "longitude": -46.8419086035,
        "localName": "Posto 7 - Itatiba"
    },
    {
        "installation": 917396,
        "name": "Frango Assado Suleste Jacareí",
        "city": "Jacarei",
        "postalCode": "12334-020",
        "address": "Rodovia Presidente Dutra, Km 65,00",
        "area": "Condominio Lagoinha",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.3011771166,
        "longitude": -46.0172425453,
        "localName": "Posto Shell - JACAREÍ/SP"
    },
    {
        "installation": 599178,
        "name": "Sete Estrelas Jacareí - Posto 42",
        "city": "Jacarei",
        "postalCode": "12305-010",
        "address": "Av. Getúlio Dorneles Vargas, 2700",
        "area": "Jardim California",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2698027788,
        "longitude": -45.9501904324,
        "localName": "Sete Estrelas"
    },
    {
        "installation": 485141,
        "name": "Retão Da Dutra (Antigo Rodrigão)",
        "city": "Jacarei",
        "postalCode": "12307-000",
        "address": "Av. Siqueira Campos,641",
        "area": "Jardim Esper",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.296931298,
        "longitude": -45.9592944529,
        "localName": "Posto Petrobrás"
    },
    {
        "installation": 671628,
        "name": "Auto Posto Ppr",
        "city": "Jaguariuna",
        "postalCode": "13820-000",
        "address": "Rua Dois, S / Nº  Lote 02 Quadra H - Cep 13910-972 - Caixa Postal 170",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.6350889951,
        "longitude": -47.0030769072,
        "localName": "PPR AUTO POSTO GNV"
    },
    {
        "installation": 4002109437,
        "name": "Rodoposto Mirante ",
        "city": "Jarinu",
        "postalCode": "13240-000",
        "address": "Rod. Dom Pedro I, SN - KM 89",
        "area": "Ponte Alta",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.0372043131,
        "longitude": -46.687312784,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 494797,
        "name": "Auto Posto Caminho Dos Passaros Ltda",
        "city": "Jundiai",
        "postalCode": "13215-010",
        "address": "Av. Antonio Frederico Ozanan,  3.400",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1863175705,
        "longitude": -46.8762580037,
        "localName": "REDE 7 - Auto Posto Caminho dos Pássaros"
    },
    {
        "installation": 650121,
        "name": "Centro Automotivo Ceaj",
        "city": "Jundiai",
        "postalCode": "13214-525",
        "address": "Av. Armando Giasetti 333, S / Nº",
        "area": "Jardim Torres Sao Jose",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1671197035,
        "longitude": -46.9171342454,
        "localName": "Posto Ipiranga + GNV"
    },
    {
        "installation": 535507,
        "name": "Figueira Cia Ipiranga",
        "city": "Jundiai",
        "postalCode": "13201-750",
        "address": "Rua Lacerda Franca, 180 ",
        "area": "Vila Arens Ii",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1963438531,
        "longitude": -46.8723419806,
        "localName": "POSTO IPIRANGA COM GNV VILA ARENS"
    },
    {
        "installation": 498454,
        "name": "Wf Jundiaí ",
        "city": "Jundiai",
        "postalCode": "13202-284",
        "address": "Rua Brasil, 2272",
        "area": "Vila Isabel Eber",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2178946219,
        "longitude": -46.8720375801,
        "localName": "WF Jundiaí"
    },
    {
        "installation": 489577,
        "name": "Gás Jundiaí ",
        "city": "Jundiai",
        "postalCode": "13216-181",
        "address": "Av. 14 De Dezembro,3200",
        "area": "Vila Joana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2254146679,
        "longitude": -46.8742960626,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 547340,
        "name": "Posto Oasis De Limeira",
        "city": "Limeira",
        "postalCode": "13486-190",
        "address": "Av. Major José Levy Sobrinho, 1.516  Limeira",
        "area": "Boa Vista",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.5576645742,
        "longitude": -47.3840879945,
        "localName": "Auto Posto Novo Oasis (Ipiranga) (GNV)"
    },
    {
        "installation": 501347,
        "name": "Modelo De Limeira",
        "city": "Limeira",
        "postalCode": "13480-180",
        "address": "Rua Boa Morte,33",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.5681747953,
        "longitude": -47.3994427457,
        "localName": "Modelo de Limeira"
    },
    {
        "installation": 4000281613,
        "name": "Auto Posto Brasil Gás Sp  Rj Ltda",
        "city": "Lorena",
        "postalCode": "12605-720",
        "address": "Rod. Presidente Dutra, Km 52",
        "area": "Lorena",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7458018262,
        "longitude": -45.0971957993,
        "localName": "Posto GNV Brasil"
    },
    {
        "installation": 685291,
        "name": "Frango Assado Louveira",
        "city": "Louveira",
        "postalCode": "13290-000",
        "address": "Via Anhanguera, Km 72",
        "area": "Louveira",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.0861049157,
        "longitude": -46.9743322176,
        "localName": "Posto Petrobras"
    },
    {
        "installation": 620193,
        "name": "Auto Posto Oitenta",
        "city": "Maua",
        "postalCode": "09360-120",
        "address": "Av. Capitão João, 566",
        "area": "Matriz",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6720765696,
        "longitude": -46.4585885936,
        "localName": "Shell"
    },
    {
        "installation": 489574,
        "name": "Kll Posto De Abastecimento E Serviços",
        "city": "Maua",
        "postalCode": "09370-800",
        "address": "Av. Papa João Xxiii, 2009",
        "area": "Vila Noemia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6824386907,
        "longitude": -46.4693571079,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 516421,
        "name": "Posto Equipe Quality",
        "city": "Mogi Das Cruzes",
        "postalCode": "08780-430",
        "address": "Rua Professor Alvaro Pavan X Rua Manoel Bezerra Lima Filho",
        "area": "Centro Civico",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.515140252,
        "longitude": -46.1814506302,
        "localName": "Posto Shell - Posto Equipe Quality"
    },
    {
        "installation": 919504,
        "name": "M Super Combustiveis E Lubrificantes Ltda  Me",
        "city": "Mogi Das Cruzes",
        "postalCode": "08750-600",
        "address": "Av. Lourenço De Souza Franco, 959",
        "area": "Jundiapeba",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5453303757,
        "longitude": -46.2526686721,
        "localName": "M SUPER COMB E LUBRIF LTDA"
    },
    {
        "installation": 499932,
        "name": "Auto Posto Titans (Antigo Vipam Ii )",
        "city": "Mogi Das Cruzes",
        "postalCode": "08735-200",
        "address": "Rua Frederico R. Jagher, 27",
        "area": "Vila Lavinia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5352361191,
        "longitude": -46.2189310495,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 621366,
        "name": "Itamarathy Auto Posto E Acessórios",
        "city": "Mogi Das Cruzes",
        "postalCode": "08773-380",
        "address": "Av. Francisco Rodrigues Filho, 695",
        "area": "Vila Mogilar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5166183508,
        "longitude": -46.1905685021,
        "localName": "Itamarathy Auto Posto Acessórios"
    },
    {
        "installation": 489225,
        "name": "João Xxiii Automobile",
        "city": "Mogi Das Cruzes",
        "postalCode": "08773-120",
        "address": "Av. José Meloni,1280",
        "area": "Vila Mogilar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5097569975,
        "longitude": -46.1953813636,
        "localName": "Posto Petrobras"
    },
    {
        "installation": 729833,
        "name": "Auto Posto Parque Cidade Nova Ltda.",
        "city": "Mogi-Guacu",
        "postalCode": "13840-000",
        "address": "Av. Bandeirantes, 1135",
        "area": "Mogiguacu",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.3487468612,
        "longitude": -46.9468892769,
        "localName": "Auto Posto Parque Cidade Nova GNV"
    },
    {
        "installation": 871140,
        "name": "Rio Guaçu",
        "city": "Mogi-Guacu",
        "postalCode": "13840-000",
        "address": "Av. Waldemar Armani, 300",
        "area": "Mogiguacu",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.3764437298,
        "longitude": -46.9721703176,
        "localName": "Posto Petrobras"
    },
    {
        "installation": 920077,
        "name": "Auto Posto Rvm Maxi Ltda.",
        "city": "Mogi-Mirim",
        "postalCode": "13803-070",
        "address": "Rod. Adhemar De Barros, Km 159",
        "area": "Jardim Pissinatti",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.4530652458,
        "longitude": -46.9818832628,
        "localName": "Postos RVM petronac Mogi Mirim"
    },
    {
        "installation": 679741,
        "name": "Posto Maxxi Gnv",
        "city": "Mogi-Mirim",
        "postalCode": "13806-310",
        "address": "Av. Alcindo Barbosa, 1335",
        "area": "Parque Da Imprensa",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.4235784639,
        "longitude": -46.9805016154,
        "localName": "Maxxi Gnv Auto Posto"
    },
    {
        "installation": 4000046118,
        "name": "Petrosasco",
        "city": "Osasco",
        "postalCode": "06236-110",
        "address": "Av. Getulio Vargas, 1485",
        "area": "Jardim Piratininga",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5057486395,
        "longitude": -46.7889042497,
        "localName": "PETROSASCO"
    },
    {
        "installation": 815596,
        "name": "Auto Posto Chic Ltda",
        "city": "Osasco",
        "postalCode": "06216-240",
        "address": "Rua Ari Barroso, 101",
        "area": "Presidente Altino",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.528149218,
        "longitude": -46.7730053422,
        "localName": "Posto AUTO POSTO CHIC + GNV"
    },
    {
        "installation": 476399,
        "name": "Bussocaba",
        "city": "Osasco",
        "postalCode": "06216-000",
        "address": "Av. Fuad Auada,240",
        "area": "Presidente Altino",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5254992763,
        "longitude": -46.7740226736,
        "localName": "Br"
    },
    {
        "installation": 516190,
        "name": "Alternativa",
        "city": "Osasco",
        "postalCode": "06018-090",
        "address": "Rua Narciso Sturlini, 248",
        "area": "Vila Bussocaba",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5412877519,
        "longitude": -46.7744077072,
        "localName": "Posto BR + GNV"
    },
    {
        "installation": 763593,
        "name": "Auto Posto Sena Ltda.",
        "city": "Osasco",
        "postalCode": "06298-190",
        "address": "Av Pres. Kennedy, 6160 Ou Rua Sergipe 05 Rochedale",
        "area": "Vila Sao Jose",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5211201344,
        "longitude": -46.782622802,
        "localName": "Auto Posto Sena Ltda."
    },
    {
        "installation": 729265,
        "name": "C. A. Ponte Metalica (Antigo Servacar Com. E Serv. Ltda. Ss Osasco)",
        "city": "Osasco",
        "postalCode": "06020-015",
        "address": "Av Dos Autonomistas, 1987",
        "area": "Vila Yara",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5398597884,
        "longitude": -46.7720773484,
        "localName": "Loja Shell Select - POSTO SHELL - CENTRO AUT PONTE METALICA DE OSASCO"
    },
    {
        "installation": 970241,
        "name": "Centro De Serviços Paz Gts Ltda - Antigo Promissão",
        "city": "Osasco",
        "postalCode": "06298-000",
        "address": "Av. Pres. Kenedy, 5031",
        "area": "Vila dos Remedios",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.52101626685685,
        "longitude": -46.74877818825471,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 790684,
        "name": "Posto Jardim Okinawa Ltda",
        "city": "Paulinia",
        "postalCode": "13140-000",
        "address": "Av Jose Alvaro Delmonde, 53",
        "area": "Paulinia",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.8003183643,
        "longitude": -47.1316770782,
        "localName": "Posto Shell"
    },
    {
        "installation": 4001525378,
        "name": "Sete Estrelas Com De Derivados ",
        "city": "Pindamonhangaba",
        "postalCode": "12421-010",
        "address": " Rodovia Sp-062, 2150 - Mombaça, Pindamonhangaba ,2150",
        "area": "Jardim Baronesa",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.948529317,
        "longitude": -45.4785488757,
        "localName": "Sete Estrelas"
    },
    {
        "installation": 687540,
        "name": "Brasil Gas Pinda",
        "city": "Pindamonhangaba",
        "postalCode": "12441-010",
        "address": "Av. Dr. José Monteiro Machado Cesar, 1250",
        "area": "Lot Joao T Fernandes",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9274246455,
        "longitude": -45.3563280023,
        "localName": "GNV Brasil"
    },
    {
        "installation": 784777,
        "name": "Auto Posto Cidade Nova Pinda",
        "city": "Pindamonhangaba",
        "postalCode": "12414-020",
        "address": "Av. Gastao Vidigal Neto, 1200",
        "area": "Pindamonhangaba",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9402026572,
        "longitude": -45.4062992946,
        "localName": "Posto BRASIL + GNV | Cidade Nova Pinda"
    },
    {
        "installation": 4000771786,
        "name": "Auto Posto Diamante De Piracicaba Ltda",
        "city": "Piracicaba",
        "postalCode": "13417-370",
        "address": "Av. Luiciano Guidotti, 420",
        "area": "Higienopolis",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7410114618,
        "longitude": -47.6416062538,
        "localName": "Posto Amaral (GNV)"
    },
    {
        "installation": 495646,
        "name": "Costa Prado",
        "city": "Piracicaba",
        "postalCode": "13417-670",
        "address": "Av. Prof.Alberto Valter Sachs,2600",
        "area": "Nova America",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7263127826,
        "longitude": -47.626355805,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 542887,
        "name": "Tiger Auto Posto",
        "city": "Piracicaba",
        "postalCode": "13414-018",
        "address": "Av. Limeira , 950",
        "area": "Vila Areao",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.703152011,
        "longitude": -47.6476545947,
        "localName": "Posto GT Shopping"
    },
    {
        "installation": 656330,
        "name": "Portal De Poá Auto Posto",
        "city": "Poa",
        "postalCode": "08559-400",
        "address": "Rua Euclides Da Cunha, 314",
        "area": "Vila Bandeirantes",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5190523685,
        "longitude": -46.3319309856,
        "localName": "Posto Portal de Poá"
    },
    {
        "installation": 4001685227,
        "name": "Rede Boxter Poá",
        "city": "Poa",
        "postalCode": "08557-700",
        "address": "Rua Fox Do Iguaçu 110",
        "area": "Vila Lucia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5086742487304,
        "longitude": -46.3416334291069,
        "localName": "Posto Boxter"
    },
    {
        "installation": 659123,
        "name": "Caturama  Antigo Rfm ",
        "city": "Rio Claro",
        "postalCode": "13503-183",
        "address": "Av. Tancredo De Almeida Neves, 581",
        "area": "Cidade Claret",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.4164476746,
        "longitude": -47.5734807908,
        "localName": "Posto Shell GNV RIO CLARO"
    },
    {
        "installation": 502232,
        "name": "Rio Clarense Via Brasil",
        "city": "Rio Claro",
        "postalCode": "13503-245",
        "address": "Rua 22 Jsp,  Nº 801 Ou Av Claret 1",
        "area": "Jardim Mirassol",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.4188308469,
        "longitude": -47.5768731329,
        "localName": "Auto Posto Rio Clarense II com Gnv Ltda"
    },
    {
        "installation": 4001928706,
        "name": "Auto Posto Brasil Gas Luiz De",
        "city": "Santa Barbara Doeste",
        "postalCode": "13457-056",
        "address": "Rua Wilcom Pereira 130",
        "area": "Jardim Paraiso",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7662551867,
        "longitude": -47.3987860461,
        "localName": "Posto Brasil Gas"
    },
    {
        "installation": 966767,
        "name": "Tenda Gnv Com De Comb E Gas Natural Veicular Ltda",
        "city": "Santa Barbara Doeste",
        "postalCode": "13454-000",
        "address": "Av. Da Agricultura, S / Nº",
        "area": "Vila Mollon Iv",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.7462625257,
        "longitude": -47.3644007565,
        "localName": "Posto Tenda GNV"
    },
    {
        "installation": 490226,
        "name": "Cia Bras. De Distibuicao Loja 2301  Extra",
        "city": "Santo Andre",
        "postalCode": "09210-570",
        "address": "Rua Visconde De Taunay,216",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6522082583,
        "longitude": -46.525424604,
        "localName": "Posto Shell - CIA BRASILEIRA DE DISTRIBUICAO"
    },
    {
        "installation": 472422,
        "name": "Catequese Auto Posto",
        "city": "Santo Andre",
        "postalCode": "09090-401",
        "address": "Av. Catequese,1253",
        "area": "Vila Guiomar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6589291675,
        "longitude": -46.5400376313,
        "localName": "Catequese Auto Posto"
    },
    {
        "installation": 547358,
        "name": "Lívia Auto Posto",
        "city": "Santo Andre",
        "postalCode": "09090-380",
        "address": "Av. José Antônio De Almeida Amazonas, 551",
        "area": "Vila Guiomar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6595737393,
        "longitude": -46.539703619,
        "localName": "Livia Auto Posto Ltda"
    },
    {
        "installation": 647761,
        "name": " Posto Gp Gnv Ltda",
        "city": "Santo Andre",
        "postalCode": "09111-340",
        "address": "Av. Giovanni Battista Pirelli, 1789",
        "area": "Vila Homero Thon",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6643315385,
        "longitude": -46.4908634479,
        "localName": "Posto GP GNV Ltda"
    },
    {
        "installation": 4001609798,
        "name": "O Estadão De Santo André Antigo Cabeça Branca ",
        "city": "Santo Andre",
        "postalCode": "09290-340",
        "address": "Av. Dos Estados, 6700",
        "area": "Vila Metalurgica",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6522165774572,
        "longitude": -46.5142360278079,
        "localName": "Auto Posto Estadão - Redee Setee"
    },
    {
        "installation": 480148,
        "name": "Posto De Serviços Trilegal (Antigo Nn Santo André)",
        "city": "Santo Andre",
        "postalCode": "09220-570",
        "address": "Av. Dos Estados,7225",
        "area": "Vila Metalurgica",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6514905414,
        "longitude": -46.5106588476,
        "localName": "Posto de Serviços Trilegal"
    },
    {
        "installation": 982809,
        "name": "Auto Posto Arrastão Ltda.",
        "city": "Santos",
        "postalCode": "11010-201",
        "address": "Rua Cristiano Otoni, 56 Ou R Visc De Sao Leopoldo S N",
        "area": "Centro",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.9314403303,
        "longitude": -46.3373956827,
        "localName": "Posto BR / GNV - Auto Posto Arrastão"
    },
    {
        "installation": 4001470873,
        "name": "Formula Indy Comercio E Serviços Automotivos Ltda",
        "city": "Santos",
        "postalCode": "11085-202",
        "address": "Av Nossa Senhora De Fátima 302",
        "area": "Chico De Paula",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.9350353389,
        "longitude": -46.362575879,
        "localName": "Formula Indy Com. e Serviços Automotivos (RodOil)"
    },
    {
        "installation": 564510,
        "name": "Zangão Serviços Automotivos",
        "city": "Sao Caetano Do Sul",
        "postalCode": "09550-051",
        "address": "Av. Goiás, 3144",
        "area": "Barcelona",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6224123481,
        "longitude": -46.5493172168,
        "localName": "Auto Posto BR - Zangao Serviços Automotivos"
    },
    {
        "installation": 472670,
        "name": "Auto Posto Eco Gas (Antigo Eco Gás  Simi)",
        "city": "Sao Caetano Do Sul",
        "postalCode": "09550-700",
        "address": "Av. Prosperidade,367",
        "area": "Prosperidade",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6108757195,
        "longitude": -46.551262408,
        "localName": "GNV"
    },
    {
        "installation": 4000207230,
        "name": "Br  Posto 800 Milhas",
        "city": "Sao Vicente",
        "postalCode": "11320-090",
        "address": "Av. Presidente Wilson, 759",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.9687216143,
        "longitude": -46.3761277047,
        "localName": "Posto BR 800 Milhas (GNV)"
    },
    {
        "installation": 713867,
        "name": "Posto Brasil Gás Sumaré",
        "city": "Sumare",
        "postalCode": "13177-433",
        "address": "R. França, 15",
        "area": "Jd Santa Maria Nova Veneza",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.798351427,
        "longitude": -47.2237889923,
        "localName": "Auto Posto Brasil Gás Flora de Sumaré"
    },
    {
        "installation": 4001953781,
        "name": "Itamarathy Auto Posto E Acessórios Ltda – Itamarathy  V ",
        "city": "Suzano",
        "postalCode": "08694-000",
        "address": "Avenida Francisco Marengo 95",
        "area": "Jardim Revista",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.519095377,
        "longitude": -46.2990960598,
        "localName": "Itamarathy Auto posto e acessórios Ltda – Itamarathy  V "
    },
    {
        "installation": 800248,
        "name": "Posto Quality Casablanca Ltda",
        "city": "Suzano",
        "postalCode": "08673-000",
        "address": "Av Armando Salles De Oliveira, 2185",
        "area": "Parque Suzano",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5520477498,
        "longitude": -46.3192208054,
        "localName": "Posto Shell - Posto Quality Casablanca"
    },
    {
        "installation": 684433,
        "name": "Suzan Petro",
        "city": "Suzano",
        "postalCode": "08685-000",
        "address": "Av. Ver. João Baptista Fitipaldi, 300",
        "area": "Vila Maluf",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.532438275,
        "longitude": -46.3064373179,
        "localName": "Posto BR"
    },
    {
        "installation": 4001926330,
        "name": "Posto Abu-Dhabi",
        "city": "Suzano",
        "postalCode": "08613-135",
        "address": "Rua Doutor Prudente De Moraes 4.135",
        "area": "Vila Santana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5408732988,
        "longitude": -46.2730093245,
        "localName": "Posto Abu-Dhabi - Ipiranga"
    },
    {
        "installation": 547381,
        "name": "Térios Auto Posto",
        "city": "São Bernardo Do Campo",
        "postalCode": "09721-230",
        "address": "Rua Alferes Bonilha, 531",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.7179451508,
        "longitude": -46.5500832497,
        "localName": "Terios Auto Posto"
    },
    {
        "installation": 518120,
        "name": "Auto Posto Vergueirão",
        "city": "São Bernardo Do Campo",
        "postalCode": "09601-000",
        "address": "Av. Sen. Vergueiro, 2861",
        "area": "Jardim Copacabana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6706533583,
        "longitude": -46.5602475975,
        "localName": "Auto Posto Vergueirão"
    },
    {
        "installation": 546262,
        "name": "Portal De São Bernardo Serviços",
        "city": "São Bernardo Do Campo",
        "postalCode": "09600-000",
        "address": "Av. Sen. Vergueiro 2630 X Av. Winston Churchil",
        "area": "Jardim Copacabana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6697664932,
        "longitude": -46.5595202666,
        "localName": "Auto Posto Ipiranga - Portal de São Bernardo"
    },
    {
        "installation": 493143,
        "name": "Vip Auto Posto Ltda",
        "city": "São Bernardo Do Campo",
        "postalCode": "09750-000",
        "address": "Av. Senador Vergueiro,1032",
        "area": "Jardim Do Mar",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6854878131,
        "longitude": -46.5543415731,
        "localName": "Posto Com GNV"
    },
    {
        "installation": 547343,
        "name": "Queops Auto Posto Ltda",
        "city": "São Bernardo Do Campo",
        "postalCode": "09771-060",
        "address": "Rua Princesa Francisca Carolina, 211",
        "area": "Jardim Nova Petropolis",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.7021786655,
        "longitude": -46.5421467493,
        "localName": "Queop S Auto Posto Limitada"
    },
    {
        "installation": 480135,
        "name": "Muffatto Sbc",
        "city": "São Bernardo Do Campo",
        "postalCode": "09823-000",
        "address": "Rua Frei Damião, 355",
        "area": "Rudge Ramos",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.67270917530257,
        "longitude": -46.57156580362459,
        "localName": "Posto gasolina (“Grupo Muffato”)"
    },
    {
        "installation": 520236,
        "name": "Rede De Postos Sete Estrelas  Penedo - Posto 04",
        "city": "São José Dos Campos",
        "postalCode": "12237-071",
        "address": "Rua Penedo, 1051",
        "area": "Conj Res Trinta E Um De Marco",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2407486517,
        "longitude": -45.9167821624,
        "localName": "Sete Estrelas"
    },
    {
        "installation": 541246,
        "name": "Posto De Serviços Bolla Branca",
        "city": "São José Dos Campos",
        "postalCode": "12235-200",
        "address": "Av. João Batista De Souza Soares, 255",
        "area": "Jardim America",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2231655514,
        "longitude": -45.9008206904,
        "localName": "Posto de Serviços Bolla Branca"
    },
    {
        "installation": 819499,
        "name": "Jcn Vale",
        "city": "São José Dos Campos",
        "postalCode": "12216-550",
        "address": "Rua Jordania, 152",
        "area": "Jardim Augusta",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2017658721,
        "longitude": -45.8816489468,
        "localName": "Posto Petrobras - GNV / Shopping Vale"
    },
    {
        "installation": 830546,
        "name": "Bandeirante",
        "city": "São José Dos Campos",
        "postalCode": "12245-031",
        "address": "Av. Dr. Nelson D´Ávila,1650",
        "area": "Jardim Sao Dimas",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2006857421,
        "longitude": -45.8858987422,
        "localName": "Posto Shell"
    },
    {
        "installation": 476400,
        "name": "Rede De Postos Sete Estrelas  Tejo - Posto 31",
        "city": "São José Dos Campos",
        "postalCode": "12245-021",
        "address": "Rua Paraibuna,1340",
        "area": "Jardim Sao Dimas",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2036635183,
        "longitude": -45.8852010221,
        "localName": "Sete Estrelas"
    },
    {
        "installation": 536431,
        "name": "Auto Posto Gascem Ltda.",
        "city": "São José Dos Campos",
        "postalCode": "12231-675",
        "address": "Av. Cidade Jardim, 920",
        "area": "Jardim Satelite",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2210465074,
        "longitude": -45.8851954482,
        "localName": "BR Mania"
    },
    {
        "installation": 677580,
        "name": "Auto Posto Master Vale Antigo Gran Vale",
        "city": "São José Dos Campos",
        "postalCode": "12231-675",
        "address": "Av. Cidade Jardim, 764",
        "area": "Jardim Satelite",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2201467264,
        "longitude": -45.885558838,
        "localName": "Gran Vale Comércio"
    },
    {
        "installation": 796095,
        "name": "Rede De Postos Sete Estrelas Tropical - Posto 47",
        "city": "São José Dos Campos",
        "postalCode": "12237-823",
        "address": "Av Dr Sebastiao Henrique Da Cunha Pontes, 5830",
        "area": "Pq Resid Aquarius",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2341917292,
        "longitude": -45.9145124288,
        "localName": "Sete Estrelas"
    },
    {
        "installation": 516092,
        "name": "Mulffatto Sjc",
        "city": "São José Dos Campos",
        "postalCode": "12220-140",
        "address": "R. Aldo José de Souza, 733-711",
        "area": "Res Tatetuba",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.18534540273655,
        "longitude": -45.85477781599666,
        "localName": "Muffato"
    },
    {
        "installation": 793685,
        "name": "Auto Posto Master Cidade (Mineirinho Sjc)",
        "city": "São José Dos Campos",
        "postalCode": "12211-230",
        "address": "Rua Franz De Castro Holzwarth, 51",
        "area": "Santana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1700202929456,
        "longitude": -45.89142136430421,
        "localName": "Auto Posto Mp de Sjcampos"
    },
    {
        "installation": 520237,
        "name": "Vitrine",
        "city": "São José Dos Campos",
        "postalCode": "12245-524",
        "address": "Av. Nelson D'Ávila, 1810",
        "area": "Vila Betania",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.2020913157,
        "longitude": -45.8855722514,
        "localName": "Posto Petrobras"
    },
    {
        "installation": 713869,
        "name": "Rede De Postos Sete Estrelas Crylor - Posto 49",
        "city": "São José Dos Campos",
        "postalCode": "12209-750",
        "address": "Rua Benedito Juliao Machado, 49",
        "area": "Vila Guarani",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1747048558,
        "longitude": -45.8771285824,
        "localName": "Auto Posto Torrão"
    },
    {
        "installation": 518101,
        "name": "Auto Posto Brasil Gas Dutra",
        "city": "São José Dos Campos",
        "postalCode": "12220-140",
        "address": "Rua Patativa, 631",
        "area": "Vila Industrial",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.1850532864,
        "longitude": -45.8530540361,
        "localName": "Auto Posto GNV Brasil Dutra"
    },
    {
        "installation": 546485,
        "name": "West Park",
        "city": "São Paulo",
        "postalCode": "05036-040",
        "address": "Av. Marques De São Vicente, 3000",
        "area": "Agua Branca",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5150439105,
        "longitude": -46.6885720365,
        "localName": "Westpark Auto Posto"
    },
    {
        "installation": 4000545020,
        "name": "Serviços Automotivos Pedrodavi Ltda.",
        "city": "São Paulo",
        "postalCode": "03022-000",
        "address": "Rua Paulo Andriguetti, 1.484",
        "area": "Alto Do Pari",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5242151022,
        "longitude": -46.6066487134,
        "localName": "Posto Singular (GNV)"
    },
    {
        "installation": 518095,
        "name": "Playgas",
        "city": "São Paulo",
        "postalCode": "01139-003",
        "address": "Av. Marques De São Vicente, 999",
        "area": "Barra Funda",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5209719226,
        "longitude": -46.6702527537,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 814653,
        "name": "Lider Santos Dumont",
        "city": "São Paulo",
        "postalCode": "01108-000",
        "address": "Av Do Estado, 1036",
        "area": "Bom Retiro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5242480867,
        "longitude": -46.6319215934,
        "localName": "Posto Petrobras"
    },
    {
        "installation": 190108,
        "name": "Auto Posto Império",
        "city": "São Paulo",
        "postalCode": "03019-000",
        "address": "Rua Joaquim Carlos, 1380",
        "area": "Bras",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.5242757895,
        "longitude": -46.608143637,
        "localName": "Rede Duque"
    },
    {
        "installation": 552188,
        "name": "Auto Posto Pétala Azul",
        "city": "São Paulo",
        "postalCode": "03101-003",
        "address": "Av. Alcantara Machado, 1655",
        "area": "Bras",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5507097519,
        "longitude": -46.6084372881,
        "localName": "Posto Boxter"
    },
    {
        "installation": 535510,
        "name": "Brazatlas",
        "city": "São Paulo",
        "postalCode": "03004-000",
        "address": "R. Do Gasômetro, 570",
        "area": "Bras",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5442219477,
        "longitude": -46.6206794556,
        "localName": "BR Mania Conveniência"
    },
    {
        "installation": 518103,
        "name": "Tropical Gasolina Serviços Automotivos Ltda",
        "city": "São Paulo",
        "postalCode": "03102-000",
        "address": "Av. Alcantara Machado, 360",
        "area": "Bras",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5525151738,
        "longitude": -46.6213950279,
        "localName": "Posto Shell"
    },
    {
        "installation": 906524,
        "name": "Duque Bandeirantes",
        "city": "São Paulo",
        "postalCode": "04558-040",
        "address": "Rua Conceição De Monte Alegre, 1680",
        "area": "Brooklin Paulista",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6018622983,
        "longitude": -46.6829525324,
        "localName": "Rede Duque"
    },
    {
        "installation": 720570,
        "name": "Auto Posto 2000",
        "city": "São Paulo",
        "postalCode": "05512-200",
        "address": "Av. Professor Francisco Morato, 2300",
        "area": "Butanta",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5851371419,
        "longitude": -46.7212116503,
        "localName": "Auto Posto Rede 1000"
    },
    {
        "installation": 675045,
        "name": "Paraná",
        "city": "São Paulo",
        "postalCode": "05509-001",
        "address": "Rua Alvarenga, 1071 Ou 1041",
        "area": "Butanta",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5726712569,
        "longitude": -46.7116784739,
        "localName": "Posto Petrobras"
    },
    {
        "installation": 374687,
        "name": "Santa Helena (Antigo Caluana)",
        "city": "São Paulo",
        "postalCode": "05582-000",
        "address": "Rua Corifeu De Azevedo Marques 44 X Av. Vital Brasil",
        "area": "Butanta",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5691693205,
        "longitude": -46.7155930821,
        "localName": "CENTRO AUTOMOTIVO SANTA HELENA LTDA"
    },
    {
        "installation": 541782,
        "name": "Ana Nery Posto Xaranga",
        "city": "São Paulo",
        "postalCode": "01522-000",
        "address": "Rua Dona Ana Nery, 1.156",
        "area": "Cambuci",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5639177329,
        "longitude": -46.6181049752,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 643741,
        "name": "Cambuci Auto Posto",
        "city": "São Paulo",
        "postalCode": "01523-010",
        "address": "Largo Do Cambuci 172",
        "area": "Cambuci",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5637351269,
        "longitude": -46.6206112863,
        "localName": "Posto Shell"
    },
    {
        "installation": 625641,
        "name": "Ouro 22",
        "city": "São Paulo",
        "postalCode": "04606-004",
        "address": "Rua Barão De Jaceguai, 1472",
        "area": "Campo Belo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6184767484,
        "longitude": -46.6724871898,
        "localName": "Posto Petrobras"
    },
    {
        "installation": 665826,
        "name": "Papa 5 Braz Leme",
        "city": "São Paulo",
        "postalCode": "02511-000",
        "address": "Av. Braz Leme, 130",
        "area": "Casa Verde",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5136078418,
        "longitude": -46.6531308156,
        "localName": "Posto Shell"
    },
    {
        "installation": 4002152893,
        "name": "Auto Posto Engenheiro 1200 Ltda",
        "city": "São Paulo",
        "postalCode": "02521-080",
        "address": "Rua Das Tangerinas,353",
        "area": "Casa Verde Media",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5042223662,
        "longitude": -46.6673060465,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 4001614257,
        "name": "Auto Posto Angra Dos Reis Ltda",
        "city": "São Paulo",
        "postalCode": "03021-000",
        "address": "Rua Catumbi 1400",
        "area": "Catumbi Brás",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5262105816,
        "longitude": -46.6013974209,
        "localName": "Posto Boxter"
    },
    {
        "installation": 719414,
        "name": "Maracanã ",
        "city": "São Paulo",
        "postalCode": "05835-003",
        "address": "Est. De Itapecerica, 1397",
        "area": "Cidade Auxiliadora",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.647583215,
        "longitude": -46.7468888555,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 756900,
        "name": "Posto De Serviço Santa Rita Do Monte Azul",
        "city": "São Paulo",
        "postalCode": "05835-002",
        "address": "Est.De Itapecerica, 1422/ 1322",
        "area": "Cidade Auxiliadora",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6471640121,
        "longitude": -46.7475571837,
        "localName": "Rede Nacional - Posto de Serviço Santa Rita do Monte Azul Ltda."
    },
    {
        "installation": 713742,
        "name": "Auto Posto Br Sul",
        "city": "São Paulo",
        "postalCode": "04801-010",
        "address": "Av. Sen Teotonio Vilela, 2.631",
        "area": "Cidade Dutra",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.7242291108,
        "longitude": -46.6988902349,
        "localName": "Posto Orion com Gnv"
    },
    {
        "installation": 707488,
        "name": "Wgás Ii Rsb",
        "city": "São Paulo",
        "postalCode": "04801-010",
        "address": "Av. Senador Teotonio Vilela, 2004",
        "area": "Cidade Dutra",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.7191482653,
        "longitude": -46.6993329166,
        "localName": "W Gás RB Auto Ltda."
    },
    {
        "installation": 488525,
        "name": "Auto Posto Rio Coa",
        "city": "São Paulo",
        "postalCode": "03717-001",
        "address": "Av. Assis Ribeiro, 10.410",
        "area": "Engenheiro Goulart",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4886596554,
        "longitude": -46.4650599822,
        "localName": "Auto Posto Rio Coa"
    },
    {
        "installation": 491864,
        "name": "Jurema  Sm Espaço Automotive",
        "city": "São Paulo",
        "postalCode": "03717-001",
        "address": "Av. Assis Ribeiro,540",
        "area": "Engenheiro Goulart",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4847195908,
        "longitude": -46.4765039726,
        "localName": "Posto BR - Via Marte"
    },
    {
        "installation": 4001699312,
        "name": "Silguekron",
        "city": "São Paulo",
        "postalCode": "04902-000",
        "address": "Av. Guarapiranga, 1791",
        "area": "Guarapiranga",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6678166568,
        "longitude": -46.729654887,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 813818,
        "name": "Posto De Servicos Advantgarde Ltda",
        "city": "São Paulo",
        "postalCode": "02413-000",
        "address": "Av Eng Caetano Alvares, 4605",
        "area": "Imirim",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4864833854,
        "longitude": -46.6436463825,
        "localName": "Posto Shell"
    },
    {
        "installation": 4001293768,
        "name": "Posto De Serviços Jurucê Ltda.",
        "city": "São Paulo",
        "postalCode": "04080-013",
        "address": "Av. Moreira Guimarães, 718",
        "area": "Indianopolis",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6125010315,
        "longitude": -46.657466919,
        "localName": "Posto de Serviços Jurucê Ltda."
    },
    {
        "installation": 623190,
        "name": "Argetax Posto Dallas",
        "city": "São Paulo",
        "postalCode": "05533-000",
        "address": "Av. Eliseu De Almeida, 2841 X Rua Canio Rizzo",
        "area": "Instituto De Previdencia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5874458016,
        "longitude": -46.7314188708,
        "localName": "Auto Posto Pascua Marques LTDA"
    },
    {
        "installation": 546261,
        "name": "Portal Do Ipiranga-Alesat Combustíveis - Duque Estadão ",
        "city": "São Paulo",
        "postalCode": "04210-050",
        "address": "Rua Barão De Resende, 66",
        "area": "Ipiranga",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5833151283,
        "longitude": -46.5989271664,
        "localName": "Posto BR GNV Rede Duque"
    },
    {
        "installation": 376459,
        "name": "Posto Phenix Com. Var. De Combustiveis (Antigo Nautilus-Raizen)",
        "city": "São Paulo",
        "postalCode": "04208-000",
        "address": "Rua Silva Bueno,754",
        "area": "Ipiranga",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.584799234,
        "longitude": -46.6021557467,
        "localName": "Posto Shell"
    },
    {
        "installation": 487827,
        "name": "Posto Vm Rodrigues  (Antigo Roney Peres De Brito )",
        "city": "São Paulo",
        "postalCode": "08120-590",
        "address": "R. Estevão Ribeiro Garcia, 30",
        "area": "Itaim Paulista",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4960723591,
        "longitude": -46.3915662282,
        "localName": "Posto Shell"
    },
    {
        "installation": 487831,
        "name": "Orion Auto Posto Ltda",
        "city": "São Paulo",
        "postalCode": "05345-040",
        "address": "R. Santo Eurilo, 360 ",
        "area": "Jaguare",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5536061975,
        "longitude": -46.7423072227,
        "localName": "Posto Shell"
    },
    {
        "installation": 530846,
        "name": "Posto Fábio Mota Marques Ltda (Antigo Chaparral Via Veneto )",
        "city": "São Paulo",
        "postalCode": "03704-000",
        "address": "Av. Cond. Eliabeth Robiano, 2222",
        "area": "Jardim America Da Penha",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5206280115,
        "longitude": -46.5547716568,
        "localName": "Posto Extra"
    },
    {
        "installation": 707775,
        "name": "Tex Auto Posto Ltda",
        "city": "São Paulo",
        "postalCode": "03090-000",
        "address": "Av Airton Pretini ,539",
        "area": "Jardim America Da Penha",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5255469626,
        "longitude": -46.5573454508,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 617858,
        "name": "Posto De Combustiveis Conveniência Zatec Tietê",
        "city": "São Paulo",
        "postalCode": "03704-000",
        "address": "Avenida Condessa Elisabeth Robiano 2200",
        "area": "Jardim América Da Penha",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.50804915924661,
        "longitude": -46.55136400609023,
        "localName": "Posto GULF - Posto Zatec Marginal Tietê"
    },
    {
        "installation": 550803,
        "name": "Auto Posto Duque Zagottis",
        "city": "São Paulo",
        "postalCode": "04675-230",
        "address": "Av. Eng°. Alberto De Zagottis 567, S / N",
        "area": "Jardim Anhanguera",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6690401425,
        "longitude": -46.6960548232,
        "localName": "Rede Duque"
    },
    {
        "installation": 737543,
        "name": "Batalha",
        "city": "São Paulo",
        "postalCode": "05593-090",
        "address": "Rua: Azem Abdala Azem, 2600",
        "area": "Jardim Bonfiglioli",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5810613919,
        "longitude": -46.7311078006,
        "localName": "Posto Shell + GNV | Batalha"
    },
    {
        "installation": 516186,
        "name": "Posto De Serviço Iramaia Morumbi Ltda",
        "city": "São Paulo",
        "postalCode": "04707-000",
        "address": "Av. Roque Petroni Jr,984",
        "area": "Jardim Das Acacias",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6232288414,
        "longitude": -46.6961664457,
        "localName": "Posto Shell"
    },
    {
        "installation": 617299,
        "name": "Centro De Abastecimento De Gás Milena Ltda  Niagara",
        "city": "São Paulo",
        "postalCode": "02701-000",
        "address": "Av. Otaviano Alves De Lima, 3950 Ou 3926",
        "area": "Jardim Das Gracas",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5065346025,
        "longitude": -46.6959181604,
        "localName": "Shell"
    },
    {
        "installation": 1039643,
        "name": "Posto De Serviços Florida Ltda",
        "city": "São Paulo",
        "postalCode": "02337-070",
        "address": "Av. Nova Cantareira, 2315",
        "area": "Jardim Franca",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4773433013,
        "longitude": -46.6109605836,
        "localName": "Posto Serviço Flórida"
    },
    {
        "installation": 806838,
        "name": "Alicemar",
        "city": "São Paulo",
        "postalCode": "08115-000",
        "address": "Av Marechal Tito, 6034",
        "area": "Jardim Miragaia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4929316097,
        "longitude": -46.3882278332,
        "localName": "Posto Petrobras Av Marechal"
    },
    {
        "installation": 833265,
        "name": "Auto Posto Relix Ltda",
        "city": "São Paulo",
        "postalCode": "05723-400",
        "address": "R Manoel Homem De Andrade, 20",
        "area": "Jardim Santo Antonio",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6433157028,
        "longitude": -46.7334468904,
        "localName": "Rede 28 - Auto Posto Relix Ltda."
    },
    {
        "installation": 520241,
        "name": "Auto Posto Panamby",
        "city": "São Paulo",
        "postalCode": "05802-140",
        "address": "Av. Guido Caloi, 661",
        "area": "Jardim Sao Luis Zona Oeste",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6501671164,
        "longitude": -46.7263677986,
        "localName": "Shell"
    },
    {
        "installation": 503086,
        "name": "Posto De Serviços Robson Eirelli (Antigo Posto Falke  Guaximim) ",
        "city": "São Paulo",
        "postalCode": "08041-000",
        "address": "Av. Pires Do Rio, 1784",
        "area": "Jardim Sao Sebastiao",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5063866419,
        "longitude": -46.4435053435,
        "localName": "Auto Posto Takata"
    },
    {
        "installation": 759034,
        "name": "Arco Posto De Serviços Ltda",
        "city": "São Paulo",
        "postalCode": "04661-000",
        "address": "Av Interlagos, 635",
        "area": "Jardim Umuarama",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6600081458,
        "longitude": -46.6788766665,
        "localName": "ARCO POSTO DE SERVIÇOS LTDA"
    },
    {
        "installation": 799515,
        "name": "Interlaken",
        "city": "São Paulo",
        "postalCode": "04661-200",
        "address": "Av Interlagos, 2981",
        "area": "Jardim Umuarama",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6780108029,
        "longitude": -46.6830998028,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 4001897378,
        "name": "Via Brasil Ltda-Portal Da Raposo",
        "city": "São Paulo",
        "postalCode": "05550-070",
        "address": "Rua Filipe Camarão 429",
        "area": "Jardim do Lago",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.58315602928556,
        "longitude": -46.783140049278,
        "localName": "Posto De Combustível Via Brasil"
    },
    {
        "installation": 500386,
        "name": "Ms2 Auto Posto Ltda",
        "city": "São Paulo",
        "postalCode": "04696-310",
        "address": "Rua Cristalino Rolim De Freitas,36 Ou 96",
        "area": "Jurubatuba",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6637991415,
        "longitude": -46.7072368185,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 518097,
        "name": "Auto Posto Estado De São Paulo (Antigo Converrede)",
        "city": "São Paulo",
        "postalCode": "02546-000",
        "address": "Av. Eng°. Caetano Alvares S / N X Rua Antonio Lopes Pereira",
        "area": "Limao",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.5102745089,
        "longitude": -46.6699699302,
        "localName": "Auto Posto Estado de São Paulo"
    },
    {
        "installation": 682690,
        "name": "Nova Cachoeirinha",
        "city": "São Paulo",
        "postalCode": "02717-000",
        "address": "Av. Inajar De Souza, 3535",
        "area": "Limao",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4785147316,
        "longitude": -46.6734020815,
        "localName": "Posto Shell"
    },
    {
        "installation": 516189,
        "name": "Posto Da Ponte",
        "city": "São Paulo",
        "postalCode": "02715-000",
        "address": "Av. Nossa Senhora Do Ó, 1693",
        "area": "Limao",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5052744089,
        "longitude": -46.689158246,
        "localName": "Posto Shell - Rede Papa GNV"
    },
    {
        "installation": 487835,
        "name": "Posto De Serviços Popular",
        "city": "São Paulo",
        "postalCode": "04046-300",
        "address": "Av. Jabaquara, 1966",
        "area": "Mirandopolis",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6213645483,
        "longitude": -46.6402887393,
        "localName": "Posto Petrobras + GNV"
    },
    {
        "installation": 472424,
        "name": "Qualitá",
        "city": "São Paulo",
        "postalCode": "03123-010",
        "address": "Rua Sarapuí, 277",
        "area": "Mooca",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5658442377,
        "longitude": -46.6012697363,
        "localName": "Posto Petrobras"
    },
    {
        "installation": 4001470849,
        "name": "Auto Posto De Serv Oliveira Ltda",
        "city": "São Paulo",
        "postalCode": "03022-030",
        "address": "R Ferreira de Oliveira 187",
        "area": "Pari",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.52409808255587,
        "longitude": -46.60378448527701,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 480138,
        "name": "Posto De Serviços Sagitário",
        "city": "São Paulo",
        "postalCode": "03026-001",
        "address": "Rua Silva Teles,1819",
        "area": "Pari",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5239276765,
        "longitude": -46.604923245,
        "localName": "Posto de serviço Sagitario"
    },
    {
        "installation": 790683,
        "name": "Finlândia (Antigo Band Iii)",
        "city": "São Paulo",
        "postalCode": "04610-080",
        "address": "Rua Lavariz 03",
        "area": "Parque Colonial",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6166403293,
        "longitude": -46.6643964283,
        "localName": "FINLÂNDIA (ANTIGO BAND III)"
    },
    {
        "installation": 774750,
        "name": "Chow Gas Auto Posto Ltda.",
        "city": "São Paulo",
        "postalCode": "04474-440",
        "address": "Rua: Cardoso De Melo Neto, 1516",
        "area": "Parque Doroteia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6969463087,
        "longitude": -46.6328192749,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 4001621062,
        "name": "Auto Posto Pacific I",
        "city": "São Paulo",
        "postalCode": "02180-040",
        "address": "Rua Soldado Francisco Franco 48",
        "area": "Parque Novo Mundo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5229250498,
        "longitude": -46.5658226979,
        "localName": "AUTO POSTO PACIFIC I"
    },
    {
        "installation": 4002195128,
        "name": "Autoposto Nsa I ",
        "city": "São Paulo",
        "postalCode": "02181-170",
        "address": "Avenida Educador Paulo Freire 293",
        "area": "Parque Novo Mundo",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.5191716441737,
        "longitude": -46.5610117298274,
        "localName": "Singular"
    },
    {
        "installation": 4002268127,
        "name": "Autoposto Tropical",
        "city": "São Paulo",
        "postalCode": "02187-210",
        "address": "Avenida Educador Paulo Freire sn",
        "area": "Parque Novo Mundo",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.5180419192223,
        "longitude": -46.5608994463577,
        "localName": "Autoposto Tropical"
    },
    {
        "installation": 778849,
        "name": "Auto Posto Ewamaro Ltda",
        "city": "São Paulo",
        "postalCode": "03087-010",
        "address": "Rua Antonio Macedo, 650",
        "area": "Parque Sao Jorge",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5269319326,
        "longitude": -46.5641216267,
        "localName": "Auto Posto Ewamaro"
    },
    {
        "installation": 778234,
        "name": "Auto Posto Nossa Senhora Da Penha",
        "city": "São Paulo",
        "postalCode": "03636-000",
        "address": "Av. Amador Bueno Da Veiga,20",
        "area": "Penha De Franca",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5236109378,
        "longitude": -46.5444270634,
        "localName": "Auto Posto Nossa Senhora da Penha - Posto GNV"
    },
    {
        "installation": 518100,
        "name": "Coimbra",
        "city": "São Paulo",
        "postalCode": "03640-010",
        "address": "Av. Dr. Orêncio Vidigal, 340",
        "area": "Penha De Franca",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5310443437,
        "longitude": -46.5407955759,
        "localName": "Posto Shell"
    },
    {
        "installation": 677360,
        "name": "Portal Das Perdizes Nn Sumaré",
        "city": "São Paulo",
        "postalCode": "05015-040",
        "address": "Rua Dr. Franco Da Rocha, 664",
        "area": "Perdizes",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5377887063,
        "longitude": -46.6741241078,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 592076,
        "name": "Auto Posto Luz Da Radial",
        "city": "São Paulo",
        "postalCode": "03331-000",
        "address": "Av. Alvaro Ramos, 707",
        "area": "Quarta Parada",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5433778572,
        "longitude": -46.5865298423,
        "localName": "Posto Boxter"
    },
    {
        "installation": 480137,
        "name": "Auto Posto Aeromarte",
        "city": "São Paulo",
        "postalCode": "02012-090",
        "address": "Rua Faustino Pereira Matias, 86",
        "area": "Santana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5091102278,
        "longitude": -46.6271270485,
        "localName": "Aero Marte"
    },
    {
        "installation": 707497,
        "name": "Posto Bg Gnv-Posto Bela Vista",
        "city": "São Paulo",
        "postalCode": "04724-003",
        "address": "Av. João Dias, 1.978",
        "area": "Santo Amaro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6445059582,
        "longitude": -46.7197827296,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 480804,
        "name": "Nipo Brasileiro",
        "city": "São Paulo",
        "postalCode": "08115-100",
        "address": "Av.Marechal Tito, 3501",
        "area": "Sao Miguel Paulista",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4946304937,
        "longitude": -46.4110320552,
        "localName": "Auto Posto Nipo Brasileiro C/Gnv"
    },
    {
        "installation": 499316,
        "name": "Jardim Da Saúde",
        "city": "São Paulo",
        "postalCode": "04132-001",
        "address": "Av. Do Cursino,2154",
        "area": "Saude",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6205937717,
        "longitude": -46.6211421398,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 493176,
        "name": "Lagos Andinos Cia Ipiranga",
        "city": "São Paulo",
        "postalCode": "04301-012",
        "address": "Rua Miguel Stefano, 2309",
        "area": "Saude",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6334246959,
        "longitude": -46.6268319788,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 479466,
        "name": "Ml20 Imigrantes - Antigo Gavião Da Imigrantes",
        "city": "São Paulo",
        "postalCode": "04301-011",
        "address": "Av. Miguel Estéfano, 1663",
        "area": "Saude",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6244452062,
        "longitude": -46.6291045019,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 500378,
        "name": "Auto Posto Cristais Do Tatuapé Ltda  ",
        "city": "São Paulo",
        "postalCode": "03065-040",
        "address": "Rua Felipe Camarão,461",
        "area": "Tatuape",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5389064863,
        "longitude": -46.578588295,
        "localName": "Posto Setee - Auto Posto Cristais do Tatuapé"
    },
    {
        "installation": 500846,
        "name": "Gold Star",
        "city": "São Paulo",
        "postalCode": "03080-000",
        "address": "Rua Jacirendi, 850",
        "area": "Tatuape",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5281737475,
        "longitude": -46.5804046808,
        "localName": "Jet Oil - Serviços Automotivos | Ipiranga"
    },
    {
        "installation": 4002202957,
        "name": "Auto Posto Parque Piqueri Ltda",
        "city": "São Paulo",
        "postalCode": "03081-003",
        "address": "Rua Ulisses Cruz 1370",
        "area": "Tatuapé",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5270012731242,
        "longitude": -46.5762466841872,
        "localName": "AUTO POSTO PARQUE PIQUERI LTDA"
    },
    {
        "installation": 561917,
        "name": "Auto Lider Bandeiras Ltda",
        "city": "São Paulo",
        "postalCode": "01142-200",
        "address": "Av. Pres. Castelo Branco, 3345",
        "area": "Varzea Da Barra Funda",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5202466298,
        "longitude": -46.6280694047,
        "localName": "Auto Posto Líder Bandeiras Ltda"
    },
    {
        "installation": 694230,
        "name": "Auto Posto Arco Do Triunfo",
        "city": "São Paulo",
        "postalCode": "01139-040",
        "address": "Rua Doutor Moisés Kahan, 501",
        "area": "Varzea Da Barra Funda",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5193104504,
        "longitude": -46.6736160055,
        "localName": "Posto Petrobras"
    },
    {
        "installation": 649161,
        "name": "Walbrax 1 Auto Center",
        "city": "São Paulo",
        "postalCode": "08020-000",
        "address": "Av. Pires Do Rio, 458",
        "area": "Vila Americana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5005063879,
        "longitude": -46.442936285,
        "localName": "Auto Posto Center Walbrax"
    },
    {
        "installation": 4001805950,
        "name": "Auto Posto Start Aricanduva",
        "city": "São Paulo",
        "postalCode": "03517-050",
        "address": "Rua Olimpio Bras De Sousa 180",
        "area": "Vila Aricanduva",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5437993392,
        "longitude": -46.5298519851,
        "localName": "Auto Posto Start Aricanduva"
    },
    {
        "installation": 887757,
        "name": "Auto Posto Bertioga Ltda",
        "city": "São Paulo",
        "postalCode": "03181-100",
        "address": "Rua Do Acre, 54",
        "area": "Vila Bertioga",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5606400793,
        "longitude": -46.5845730034,
        "localName": "Auto Posto Bertioga LTDA - Posto Petrobras"
    },
    {
        "installation": 969487,
        "name": "Auto Posto Duque Salim",
        "city": "São Paulo",
        "postalCode": "03187-050",
        "address": "Avenida Salim Farah Maluf, 3400",
        "area": "Vila Bertioga",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5592812344,
        "longitude": -46.5776506276,
        "localName": "Rede Duque"
    },
    {
        "installation": 552986,
        "name": "Santa Cecília Cia Ipiranga",
        "city": "São Paulo",
        "postalCode": "01225-030",
        "address": "R. Frederico Steidel, 58  Sta Cecília",
        "area": "Vila Buarque",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5409304989,
        "longitude": -46.6471311439,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 574457,
        "name": "Start Auto Posto",
        "city": "São Paulo",
        "postalCode": "03216-040",
        "address": "Rua São Raimundo, 2125",
        "area": "Vila California",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6060170251,
        "longitude": -46.5513372267,
        "localName": "Shell"
    },
    {
        "installation": 743089,
        "name": "Piu Piu Auto Center Ltda.",
        "city": "São Paulo",
        "postalCode": "03401-001",
        "address": "Rua Antonio De Barros, 2181",
        "area": "Vila Carrao",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5490203873,
        "longitude": -46.5544676561,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 656603,
        "name": "Super Posto Flor Do Carrão",
        "city": "São Paulo",
        "postalCode": "03403-002",
        "address": "Av. Conselheiro Carrão, 2.673",
        "area": "Vila Carrao",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.549230702,
        "longitude": -46.535680105,
        "localName": "Posto Ale + GNV"
    },
    {
        "installation": 488914,
        "name": "Glaussea",
        "city": "São Paulo",
        "postalCode": "04254-060",
        "address": "Estrada Das Lágrimas, 2200 / Rua Tamuata 20",
        "area": "Vila Conde Do Pinhal",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6208423659,
        "longitude": -46.5924635705,
        "localName": "Posto Shell"
    },
    {
        "installation": 636458,
        "name": "Auto Posto Edu Ltda",
        "city": "São Paulo",
        "postalCode": "02260-000",
        "address": "Rua Luiz Stamatis, 220",
        "area": "Vila Constanca",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4683358655,
        "longitude": -46.5828876402,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 671663,
        "name": "Com. Varejista De Combustíveis Veloz - (Antigo  Servacar)",
        "city": "São Paulo",
        "postalCode": "03295-100",
        "address": "Av. Professor Luis Inácio Anhaia Mello, 6.143",
        "area": "Vila Ema",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5912878368,
        "longitude": -46.5417979427,
        "localName": "Posto Shell"
    },
    {
        "installation": 536432,
        "name": "Apolo 8 ",
        "city": "São Paulo",
        "postalCode": "02063-000",
        "address": "Av. Morvan Dias Figueiredo, 2391 ",
        "area": "Vila Guilherme",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5220191441,
        "longitude": -46.6091434321,
        "localName": "Posto Serviços Apolo 8"
    },
    {
        "installation": 4000157687,
        "name": "Argetax Chico Pontes",
        "city": "São Paulo",
        "postalCode": "02053-000",
        "address": "Rua Chico Pontes, 1292",
        "area": "Vila Guilherme",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5089845801,
        "longitude": -46.6005373628,
        "localName": "posto Agtax"
    },
    {
        "installation": 4002110654,
        "name": "Auto Posto J.E Ltda",
        "city": "São Paulo",
        "postalCode": "02065-010",
        "address": "Av. Joaquina Ramalho, 151",
        "area": "Vila Guilherme",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5149823894566,
        "longitude": -46.6082233248474,
        "localName": "Posto Ipiranga"
    },
    {
        "installation": 547361,
        "name": "Confiança Cia Ipiranga",
        "city": "São Paulo",
        "postalCode": "02063-000",
        "address": "Av.Morvan Dias Figueiredo, 7035",
        "area": "Vila Guilherme",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5232435047,
        "longitude": -46.566213799,
        "localName": "Posto Confiança"
    },
    {
        "installation": 665827,
        "name": "Mundo Novo",
        "city": "São Paulo",
        "postalCode": "02063-000",
        "address": "Av. Morvan Dias De Figueiredo, 6.779",
        "area": "Vila Guilherme",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5241740326,
        "longitude": -46.568659592,
        "localName": "Posto de Serviço Mundo Novo"
    },
    {
        "installation": 630926,
        "name": "Rodovias",
        "city": "São Paulo",
        "postalCode": "02063-000",
        "address": "Av. Morvan Dias De Figueiredo, 2605",
        "area": "Vila Guilherme",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5212643407,
        "longitude": -46.6048615673,
        "localName": "Posto Serviços Apolo 8"
    },
    {
        "installation": 4002110651,
        "name": "Posto Serviços Automotivos Galapagos Eireli",
        "city": "São Paulo",
        "postalCode": "05319-000",
        "address": "Av. Queiroz Filho, 1310",
        "area": "Vila Hamburguesa",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5413275218,
        "longitude": -46.730174035,
        "localName": "Posto Galapagos"
    },
    {
        "installation": 937354,
        "name": "Pêssego Com. E Serviços Combustíveis E Lubrif. Ltda",
        "city": "São Paulo",
        "postalCode": "08260-005",
        "address": "Av. Jacu Pessego, 2.681",
        "area": "Vila Jacui",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5774636498,
        "longitude": -46.4430173643,
        "localName": "Auto Posto Estação Pêssego"
    },
    {
        "installation": 491958,
        "name": "Auto Posto São Francisco Ltda      ",
        "city": "São Paulo",
        "postalCode": "05340-002",
        "address": "Av. Corifeu De Azevedo Marques, 5842",
        "area": "Vila Lageado",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5531827409,
        "longitude": -46.7564925934,
        "localName": "AUTO POSTO SÃO FRANCISCO"
    },
    {
        "installation": 4000178043,
        "name": "Posto Sena Park",
        "city": "São Paulo",
        "postalCode": "04019-000",
        "address": "Av. Sena Madureira, 1.490",
        "area": "Vila Mariana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.592265981,
        "longitude": -46.6483959907,
        "localName": "Posto Shell + GNV | Sena Park ( 24 Horas )"
    },
    {
        "installation": 382451,
        "name": "Tutóia",
        "city": "São Paulo",
        "postalCode": "04012-080",
        "address": "Rua Dr. Amâncio De Carvalho 26",
        "area": "Vila Mariana",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5818581817,
        "longitude": -46.6496919003,
        "localName": "Posto Flor de Goiás"
    },
    {
        "installation": 481392,
        "name": "Argetax Anchieta",
        "city": "São Paulo",
        "postalCode": "04246-001",
        "address": "Via Anchieta,1368",
        "area": "Vila Moinho Velho",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6136579008,
        "longitude": -46.6014070555,
        "localName": "ARGETAX ANCHIETA"
    },
    {
        "installation": 500388,
        "name": "Auto Posto 710",
        "city": "São Paulo",
        "postalCode": "04537-001",
        "address": "Rua Clodomiro Amazonas,710",
        "area": "Vila Nova Conceicao",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.590599883,
        "longitude": -46.6785881694,
        "localName": "Posto Ipiranga GNV"
    },
    {
        "installation": 518133,
        "name": "Funchal",
        "city": "São Paulo",
        "postalCode": "04551-060",
        "address": "Av. Funchal, 554 ",
        "area": "Vila Olimpia",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5954348792,
        "longitude": -46.6908391018,
        "localName": "Funchal Auto Posto"
    },
    {
        "installation": 640286,
        "name": "Auto Posto Rio Verde",
        "city": "São Paulo",
        "postalCode": "02934-000",
        "address": "Av. Rio Verde, 1822",
        "area": "Vila Pereira Cerca",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.4895263269,
        "longitude": -46.7099042143,
        "localName": "Posto Shell"
    },
    {
        "installation": 516191,
        "name": "Posto Wellcome",
        "city": "São Paulo",
        "postalCode": "05731-250",
        "address": "Av. Das Belezas,513",
        "area": "Vila Prel",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.641986718,
        "longitude": -46.7422170475,
        "localName": "Rede Nacional - Posto de Serviços Welcome Ltda."
    },
    {
        "installation": 644587,
        "name": "Auto Posto Imbo",
        "city": "São Paulo",
        "postalCode": "03342-000",
        "address": "Rua Regente Feijo, 752",
        "area": "Vila Regente Feijo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5582010363,
        "longitude": -46.5711294132,
        "localName": "Posto ALE + GNV | IMBO"
    },
    {
        "installation": 481390,
        "name": "Auto Posto Oiti",
        "city": "São Paulo",
        "postalCode": "03342-000",
        "address": "Av. Regente Feijó, 1.188",
        "area": "Vila Regente Feijo",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.5596866607,
        "longitude": -46.567862415,
        "localName": "Auto Posto Oiti"
    },
    {
        "installation": 707771,
        "name": "Auto Posto Tubarão - Antigo Rota 116 ",
        "city": "Taboao Da Serra",
        "postalCode": "06756-015",
        "address": "Rod. Regis Bitencourt, Km 270,6 Ou R Roberta Simoes Souza 1530",
        "area": "Jardim Monte Alegre",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.6077293995,
        "longitude": -46.7680635215,
        "localName": "BR GNV - AUTO POSTO ROTA 116"
    },
    {
        "installation": 696581,
        "name": "Auto Posto Dutra Sul Ltda",
        "city": "Taubate",
        "postalCode": "12010-280",
        "address": "Av. Dos Bandeirantes, 4.680",
        "area": "Centro",
        "state": "SP",
        "highFlow": true,
        "latitude": -23.0420448552,
        "longitude": -45.5748464633,
        "localName": "Ruff"
    },
    {
        "installation": 546480,
        "name": "Sete Estrelas Com. E Der. De Petr.  Bandeirantes - Filial 10",
        "city": "Taubate",
        "postalCode": "12010-280",
        "address": "Av. Bandeirantes, 5236",
        "area": "Centro",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0435060122,
        "longitude": -45.5817730537,
        "localName": "Sete Estrelas"
    },
    {
        "installation": 553779,
        "name": "Rede De Postos Sete Estrelas  Taubaté - Filail 07",
        "city": "Taubate",
        "postalCode": "12040-001",
        "address": "Av. Charles Schnneider, 1775",
        "area": "Estiva",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0248327417,
        "longitude": -45.5846885124,
        "localName": "Sete Estrelas"
    },
    {
        "installation": 545502,
        "name": "Centro Automotivo Sete Estrelas Ltda – Posto 67 ( Posto De Gás Natural Taubaté)",
        "city": "Taubate",
        "postalCode": "12091-000",
        "address": "Av. Dom Pedro I, 5391",
        "area": "Jardim Baronesa",
        "state": "SP",
        "highFlow": false,
        "latitude": -23.0451265578,
        "longitude": -45.5812782136,
        "localName": "Sete Estrelas"
    },
    {
        "installation": 647018,
        "name": "Super Tof",
        "city": "Valinhos",
        "postalCode": "13270-210",
        "address": "Av. Dos Esportes, 1092",
        "area": "Jardim Sao Jorge",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9768367471,
        "longitude": -47.0004619295,
        "localName": "Super Posto Tof"
    },
    {
        "installation": 808051,
        "name": "Auto Posto Saci Ltda.",
        "city": "Valinhos",
        "postalCode": "13278-600",
        "address": "Rod. Anhanguera Sp330,84",
        "area": "Joapiranga",
        "state": "SP",
        "highFlow": true,
        "latitude": -22.9968802024,
        "longitude": -47.0347137971,
        "localName": "Saci"
    },
    {
        "installation": 630919,
        "name": "Ferraz E Ferraz",
        "city": "Valinhos",
        "postalCode": "13277-200",
        "address": "Rua Dr. Marino Costa Terra, 2467",
        "area": "Vila Pagano",
        "state": "SP",
        "highFlow": false,
        "latitude": -22.9903916292,
        "longitude": -47.0035415691,
        "localName": "Posto Ferraz & Ferraz"
    }
]