<div class="descriptive-card">
  <div>
    <div class="descriptive-card__image-container">
      <div class="descriptive-card__image-shadow"></div>
      <qsc-picture
        class="descriptive-card__image"
        *ngIf="image"
        [breakpoints]="image.breakpoints"
        [alt]="image.alt"
        [title]="image.title"
        [fullWidth]="true"
        [fullHeight]="true"
      ></qsc-picture>
      <h3 class="descriptive-card__title" [innerHTML]="title | translate"></h3>
    </div>

    <div class="descriptive-card__description-container">
      <p
        class="descriptive-card__description"
        [innerHTML]="description | translate"
      ></p>
    </div>
  </div>
  <div class="descriptive-card__bg-gradient"></div>
</div>
