import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  MapActionModalEnum,
  MapNotificationModalEnum,
} from '@shared/components';
import {
  GoogleMapsModalService,
  IMarkerData,
  WaypointService,
} from '@shared/services';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WaypointUtilsService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly waypointService: WaypointService,
    private readonly googleMapsModalService: GoogleMapsModalService
  ) {}

  addWaypointContainer(gasStations: IMarkerData[]): void {
    const waypointsContainer = this.getWaypointsContainer();
    if (!waypointsContainer) return;

    this.updateLabel(waypointsContainer);
    this.clearWaypointsItems(waypointsContainer);

    if (this.waypointService.hasWaypoints()) {
      this.configureContainerForWaypoints(waypointsContainer);
    } else {
      this.resetContainerStyle(waypointsContainer);
    }

    this.updateContainerForWaypointCount(waypointsContainer);

    this.populateWaypoints(waypointsContainer, gasStations);
  }

  private getWaypointsContainer(): HTMLElement | null {
    return document.getElementsByClassName(
      'waypoints-container'
    )[0] as HTMLElement;
  }

  private updateLabel(container: HTMLElement): void {
    const label = container.getElementsByTagName('label')[0];
    if (label) {
      label.innerHTML = this.translateService.instant(
        '@VEHICLES-CONTENT-FUEL-STATION-INPUT-LABEL-6'
      );
    }
  }

  private clearWaypointsItems(container: HTMLElement): void {
    const waypointsItems = container.getElementsByTagName('div')[0];
    if (waypointsItems) {
      waypointsItems.innerHTML = '';
    }
  }

  private configureContainerForWaypoints(container: HTMLElement): void {
    const label = container.getElementsByTagName('label')[0];
    label.style.display = 'block';
    container.style.gap = '0.5rem';
    container.style.paddingBottom = '1rem';
  }

  private resetContainerStyle(container: HTMLElement): void {
    const label = container.getElementsByTagName('label')[0];
    label.style.display = 'none';
    container.style.gap = '0';
    container.style.paddingBottom = '0';
  }

  private updateContainerForWaypointCount(container: HTMLElement): void {
    const waypointsItems = container.getElementsByTagName('div')[0];
    if (this.waypointService.getWaypoints().length > 2) {
      container.style.paddingBottom = '0.5rem';
      waypointsItems.style.paddingRight = '0.5rem';
      waypointsItems.style.paddingBottom = '0.5rem';
    } else {
      waypointsItems.style.paddingRight = '0';
      waypointsItems.style.paddingBottom = '0';
    }
  }

  private populateWaypoints(
    container: HTMLElement,
    gasStations: IMarkerData[]
  ): void {
    const waypointsItems = container.getElementsByTagName('div')[0];
    this.waypointService.getOptimizedWaypoints().forEach((waypoint) => {
      const lat = (waypoint.location as google.maps.LatLng).lat;
      const lng = (waypoint.location as google.maps.LatLng).lng;

      const filtered = gasStations.find(
        (d) => d.latitude === Number(lat) && d.longitude === Number(lng)
      );

      if (filtered) {
        const waypointDiv = this.addWaypointInput(filtered);
        waypointsItems?.appendChild(waypointDiv);
      }
    });
  }

  private addWaypointInput(data: IMarkerData): HTMLElement {
    const waypointDiv = this.createWaypointDiv();
    const waypointInput = this.createWaypointInput(data);
    const trashIcon = this.createTrashIcon(data);

    waypointDiv.appendChild(waypointInput);
    waypointDiv.appendChild(trashIcon);

    return waypointDiv;
  }

  private createWaypointDiv(): HTMLElement {
    const waypointDiv = document.createElement('div');
    waypointDiv.className = 'waypoint-input';
    return waypointDiv;
  }

  private createWaypointInput(data: IMarkerData): HTMLInputElement {
    const waypointInput = document.createElement('input');
    waypointInput.type = 'text';
    waypointInput.value = data.name!;
    waypointInput.className = 'form-control';
    waypointInput.disabled = true;
    return waypointInput;
  }

  private createTrashIcon(data: IMarkerData): HTMLElement {
    const trashIcon = document.createElement('span');
    trashIcon.innerHTML =
      '<i aria-hidden="true" class="icon-excluir_lixeira"></i>';
    trashIcon.style.cursor = 'pointer';
    trashIcon.style.marginLeft = '10px';
    trashIcon.title = this.translateService.instant(
      '@VEHICLES-CONTENT-MAPS-TOOLTIP-2'
    );

    trashIcon.addEventListener('click', () => this.handleTrashIconClick(data));

    return trashIcon;
  }

  private handleTrashIconClick(data: IMarkerData): void {
    const modal = this.googleMapsModalService.openActionModal(
      MapActionModalEnum.Remove,
      data.name!
    );

    modal.onHide?.pipe(take(1)).subscribe(async () => {
      if (modal.content.confirm) {
        await this.removeWaypointAndRefresh(data);
        this.toggleButtonsVisibility(data);
      }
    });
  }

  private async removeWaypointAndRefresh(data: IMarkerData): Promise<void> {
    this.waypointService.removeWaypoint(data);
    this.waypointService.waypointsSubject.next(
      this.waypointService.getWaypoints()
    );
    this.googleMapsModalService.openNotificationModal(
      MapNotificationModalEnum.GasStationRemoved
    );
  }

  private toggleButtonsVisibility(data: IMarkerData): void {
    const addButton = document.querySelector(
      `.waypoint-add-btn[id="${data.installation?.toString()}"]`
    ) as HTMLButtonElement;
    if (addButton) addButton.style.display = 'block';

    const removeButton = document.querySelector(
      `.waypoint-remove-btn[id="${data.installation?.toString()}"]`
    ) as HTMLButtonElement;
    if (removeButton) removeButton.style.display = 'none';
  }

  clearWaypointsInputs(): void {
    const waypointsContainer = this.getWaypointsContainer();
    if (!waypointsContainer) return;

    this.clearLabelContent(waypointsContainer);
    this.clearWaypointsItems(waypointsContainer);
    this.resetContainerStyles(waypointsContainer);
  }

  private clearLabelContent(waypointsContainer: HTMLElement): void {
    const label = waypointsContainer.getElementsByTagName('label')[0];
    if (label) {
      label.innerHTML = '';
      label.style.display = 'none';
    }
  }

  private resetContainerStyles(waypointsContainer: HTMLElement): void {
    waypointsContainer.style.gap = '0';
    waypointsContainer.style.paddingBottom = '0';
  }
}
