import { Injectable } from '@angular/core';
import { IMarkerData } from './marker/marker-data';
import { MarkerTypeEnum } from './marker/marker-type.enum';
import { GoogleMapsFacade } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  data: IMarkerData[] = [];
  map?: google.maps.Map;
  origin = '';
  destination = '';
  originDestinationWasAdded = false;

  constructor(private readonly googleMapsFacade: GoogleMapsFacade) {}

  async initMapAsync(elementId: string): Promise<void> {
    this.map = await this.googleMapsFacade.createMapAsync(elementId);
    await this.googleMapsFacade.initializeDirectionsService();
    await this.googleMapsFacade.initializeDirectionsRendererService(this.map);

    this.googleMapsFacade.waypointService.waypoints$.subscribe(() => {
      if (this.origin && this.destination) {
        this.createRoute().then(() => {
          this.googleMapsFacade.addWaypointContainer(this.data);
        });
      }
    });

    this.originDestinationWasAdded = false;
  }

  async createRoute(): Promise<google.maps.DirectionsResult | null> {
    return new Promise((resolve, reject) => {
      this.googleMapsFacade
        .createRoute(
          this.origin,
          this.destination,
          this.googleMapsFacade.getWaypoints()
        )
        .then(async (directionsResult) => {
          if (!directionsResult) {
            reject(new Error('Failed to create route'));
            return;
          }

          const optimizedWaypoints = this.getOptimizedWaypoints(
            directionsResult?.routes[0]?.waypoint_order ?? []
          );

          this.googleMapsFacade.setOptimizedWaypoints(optimizedWaypoints);

          const route = directionsResult.routes[0].legs[0];

          if (!this.originDestinationWasAdded) {
            this.addMarker(route.start_location, this.origin);
            this.addMarker(route.end_location, this.destination);
            this.originDestinationWasAdded = true;
          }

          this.googleMapsFacade.renderRoute(directionsResult);

          resolve(directionsResult);
        })
        .catch(() => {
          reject(new Error('Failed to create route'));
        });
    });
  }

  clearRoute(): void {
    this.googleMapsFacade.clearRoute();
  }

  private async addMarker(
    latLng: google.maps.LatLng,
    name: string
  ): Promise<void> {
    this.googleMapsFacade.getMarkers().push(
      await this.googleMapsFacade.createMarkerAsync(
        MarkerTypeEnum.Default,
        {
          latitude: latLng.lat(),
          longitude: latLng.lng(),
          name,
        },
        this.map
      )
    );
  }

  async updateMarkers(origin?: string, destination?: string): Promise<void> {
    if (!this.map) return;

    this.origin = origin ?? '';
    this.destination = destination ?? '';

    this.clearWaypoints();
    this.googleMapsFacade.clearMarkers();
    this.googleMapsFacade.removeActionButtons();

    this.originDestinationWasAdded = false;

    if (origin && destination) {
      this.map?.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(
        this.googleMapsFacade.createLegendForRoute()
      );
    } else {
      this.map?.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(
        this.googleMapsFacade.createLegendForCovarageArea()
      );
    }

    if (this.data.length > 0) {
      this.googleMapsFacade.setMarkers(
        await Promise.all(
          this.data.map(async (data: IMarkerData) => {
            const markerType = data.highFlow
              ? MarkerTypeEnum.HighFlow
              : MarkerTypeEnum.Common;

            const marker = await this.googleMapsFacade.createMarkerAsync(
              markerType,
              data,
              this.map
            );

            if (origin && destination)
              this.googleMapsFacade.createActionButtons(
                marker,
                data,
                this.map!
              );
            return marker;
          })
        )
      );
    }
  }

  clearWaypoints(): void {
    this.googleMapsFacade.clearWaypoints();
    this.googleMapsFacade.clearWaypointsInputs();
  }

  startNavigation(): void {
    this.googleMapsFacade.startNavigation(
      this.origin,
      this.destination,
      this.googleMapsFacade.getOptimizedWaypoints()
    );
  }

  centerMap(): void {
    if (!this.googleMapsFacade.getMarkers().length || !this.map) return;

    const bounds = new google.maps.LatLngBounds();
    this.googleMapsFacade
      .getMarkers()
      .forEach((marker) => bounds.extend(marker.position!));

    this.map.fitBounds(bounds);
    this.setMaxZoom(this.map);
  }

  private getOptimizedWaypoints(
    waypointOrder: number[]
  ): google.maps.DirectionsWaypoint[] {
    return waypointOrder.map(
      (index) => this.googleMapsFacade.getWaypoints()[index]
    );
  }

  private setMaxZoom(map: google.maps.Map): void {
    const defaultZoom = 15;
    const zoomMap = map.getZoom() ?? defaultZoom;
    const maxZoom =
      this.googleMapsFacade.mapSettings.defaultOptions().maxZoom ?? defaultZoom;

    if (zoomMap > maxZoom) {
      map.setZoom(maxZoom);
    }
  }
}
