<div class="know-more">
  <p
    *ngIf="showLabel"
    class="know-more__title"
    [class.text-center]="centralizedTitle"
  >
    {{ "@KNOW-MORE-LABEL" | translate }}
  </p>
  <div class="d-flex justify-content-center justify-content-lg-start gap-3 m-0">
    <a
      class="btn-tag"
      [qscEventTrackerCategory]="gaEventCategory ?? ''"
      [qscEventTrackerAction]="blogAnalytics.action.saibaMais"
      [qscEventTrackerLabel]="'@KNOW-MORE-BUILDING' | translate"
      *ngIf="showBuildings"
      href="/predios"
      >{{ "@KNOW-MORE-BUILDING" | translate }}</a
    >
    <a
      class="btn-tag"
      [qscEventTrackerCategory]="gaEventCategory ?? ''"
      [qscEventTrackerAction]="blogAnalytics.action.saibaMais"
      [qscEventTrackerLabel]="'@KNOW-MORE-RESIDENTIAL' | translate"
      *ngIf="showResidential"
      href="/residencias"
    >
      {{ "@KNOW-MORE-RESIDENTIAL" | translate }}</a
    >
    <a
      class="btn-tag"
      [qscEventTrackerCategory]="gaEventCategory ?? ''"
      [qscEventTrackerAction]="blogAnalytics.action.saibaMais"
      [qscEventTrackerLabel]="'@KNOW-MORE-COMMERCE' | translate"
      *ngIf="showCommerce"
      href="/negocios"
      >{{ "@KNOW-MORE-COMMERCE" | translate }}</a
    >
    <a
      class="btn-tag"
      [qscEventTrackerCategory]="gaEventCategory ?? ''"
      [qscEventTrackerAction]="blogAnalytics.action.saibaMais"
      [qscEventTrackerLabel]="'@KNOW-MORE-INDUSTRIES' | translate"
      *ngIf="showIndustries"
      href="/industrias"
      >{{ "@KNOW-MORE-INDUSTRIES" | translate }}</a
    >
  </div>
</div>
