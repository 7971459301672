export enum LocalType {
  GasStation = 'Posto de Combustível',
}

export interface IMarkerData {
  installation?: number;
  name?: string;
  city?: string;
  postalCode?: string;
  address?: string;
  area?: string;
  state?: string;
  highFlow?: boolean;
  latitude?: number;
  longitude?: number;
  visible?: boolean;
  localName?: string;
}
