import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { IMarkerData } from '@shared/services/google-maps/marker/marker-data';
import { GoogleMapsService } from '@shared/services/google-maps/google-maps.service';
import { MapNotificationModalEnum } from './map-notification-modal/map-notification-modal.component';
import { GoogleMapsModalService } from '@shared/services';

@Component({
  selector: 'qsc-google-maps',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule],
  providers: [TitleCasePipe],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit, OnChanges {
  @Input() data: IMarkerData[] = [];
  @Input() origin = '';
  @Input() destination = '';
  @Input() showOnlyHighFlow = false;

  @Output() routeErrorEvent = new EventEmitter<void>();

  constructor(
    private readonly googleMapsService: GoogleMapsService,
    private readonly googleMapsModalService: GoogleMapsModalService,
    private readonly titleCasePipe: TitleCasePipe
  ) {}

  async ngOnInit(): Promise<void> {
    await this.googleMapsService.initMapAsync('map').then(async () => {
      if (this.data.length === 0) return;

      this.data = this.getValidLocations(this.data);

      this.googleMapsService.data = this.data;

      if (!this.origin || !this.destination) {
        await this.googleMapsService.updateMarkers().then(() => {
          this.googleMapsService.centerMap();
        });
        return;
      }
      this.googleMapsService.clearWaypoints();

      await this.googleMapsService
        .updateMarkers(this.origin, this.destination)
        .then(async () => {
          await this.googleMapsService.createRoute().then(() => {
            (document.activeElement as HTMLElement).blur();
            this.googleMapsModalService.openNotificationModal(
              MapNotificationModalEnum.RouteCreated
            );
          });

          this.googleMapsService.centerMap();
        })
        .catch(() => {
          (document.activeElement as HTMLElement).blur();
          this.googleMapsModalService.openNotificationModal(
            MapNotificationModalEnum.RouteError
          );
          this.googleMapsService.clearRoute();
          this.routeErrorEvent.emit();
        });
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!this.googleMapsService.map) return;

    this.data = this.getValidLocations(this.data);
    this.googleMapsService.data = this.data;

    if (
      (changes['data'] && (changes['origin'] || changes['destination'])) ||
      this.origin
    ) {
      await this.googleMapsService.updateMarkers(this.origin, this.destination);

      const highFlowChanged = !!changes['showOnlyHighFlow'];

      setTimeout(async () => {
        this.googleMapsService.clearWaypoints();

        if (!this.origin || !this.destination) return;

        await this.googleMapsService.createRoute().then(() => {
          if (!highFlowChanged) {
            (document.activeElement as HTMLElement).blur();
            this.googleMapsModalService.openNotificationModal(
              MapNotificationModalEnum.RouteCreated
            );
          }
        });
      }, 200);
      return;
    }

    if (changes['data'] && !this.origin) {
      await this.googleMapsService.updateMarkers();
    }

    setTimeout(async () => {
      this.googleMapsService.centerMap();
    }, 200);
  }

  private getValidLocations(data: IMarkerData[]): IMarkerData[] {
    return data.filter(
      (data) => data.latitude && data.longitude && data.visible
    );
  }

  public transform(data: IMarkerData): void {
    this.titleCasePipe.transform(data.name);
    this.titleCasePipe.transform(data.address);
    this.titleCasePipe.transform(data.area);
    this.titleCasePipe.transform(data.city);
  }
}
